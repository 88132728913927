import { Field, FieldProps, Form, Formik } from "formik";
import { TextInputField } from "../TextInputField";
import { Theme } from "../../Theme";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { StyledText } from "../../Styles";
import { Button } from "../Ui";

const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white },
  inputFieldAndErrorDisplayContainerStyle: { width: "350px", marginTop: "15px" },
};

const SuccessMessage = () => (
  <StyledText textStyle="regular" color="green" margin="15px 0px">
    Update successful!
  </StyledText>
);

const ErrorMessage = ({ error }: { error: string }) => (
  <StyledText textStyle="regular" color="red" margin="15px 0px">
    {error}
  </StyledText>
);

function secondsToDate(seconds: number) {
  const date = new Date(seconds * 1000);
  return date.toISOString().split("T")[0];
}
export const UserSettings = () => {
  const {
    state: { user, updatePending, updateError },
    updateUser,
  } = useContext(AuthContext);
  const signUpDate = user?.accountCreationTime && secondsToDate(user.accountCreationTime.seconds);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const updateAttemptedRef = useRef(false);

  useEffect(() => {
    if (updateAttemptedRef.current && !updatePending && !updateError) {
      setShowSuccess(true);
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
    if (updateAttemptedRef.current && !updatePending && updateError) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [updatePending, updateError]);

  return (
    <div style={{ marginLeft: "10%", marginTop: "3%" }}>
      <StyledText textStyle="heading" color={Theme.colors.darkBlue} fontWeight="500">
        Profile Settings
      </StyledText>
      <Formik
        onSubmit={(values) => {
          const { firstName, lastName } = values;
          if (firstName && lastName) {
            updateAttemptedRef.current = true;
            updateUser({ firstName, lastName });
          }
        }}
        initialValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          signUpDate,
          email: user?.email,
          paymentStatus: user?.hasPaid ? "Paid" : "Not paid",
        }}
      >
        {({ dirty }) => {
          return (
            <Form>
              <Field name="firstName">
                {({ field, meta }: FieldProps) => {
                  return (
                    <TextInputField
                      label="First name"
                      overrideStyles={textInputStyle}
                      {...field}
                      {...meta}
                      placeholder="Enter first name"
                      type="string"
                    />
                  );
                }}
              </Field>
              <Field name="lastName">
                {({ field, meta }: FieldProps) => {
                  return (
                    <TextInputField
                      label="Last name"
                      overrideStyles={textInputStyle}
                      {...field}
                      {...meta}
                      placeholder="Enter first name"
                      type="string"
                    />
                  );
                }}
              </Field>
              {signUpDate && (
                <Field name="signUpDate">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <TextInputField
                        label="Sign up date"
                        overrideStyles={textInputStyle}
                        {...field}
                        {...meta}
                        placeholder="Enter first name"
                        type="date"
                        disabled={true}
                      />
                    );
                  }}
                </Field>
              )}
              <Field name="email">
                {({ field, meta }: FieldProps) => {
                  return (
                    <TextInputField
                      label="Email"
                      overrideStyles={textInputStyle}
                      {...field}
                      {...meta}
                      placeholder="Enter first name"
                      type="string"
                      disabled={true}
                    />
                  );
                }}
              </Field>
              <Field name="paymentStatus">
                {({ field, meta }: FieldProps) => {
                  return <TextInputField label="Payment Status" overrideStyles={textInputStyle} {...field} {...meta} type="string" disabled={true} />;
                }}
              </Field>
              {dirty && (
                <Button
                  text="Update"
                  type="submit"
                  isLoading={updatePending}
                  buttonStyles={{
                    borderRadius: "15px",
                    height: "35px",
                    marginTop: "30px",
                    color: Theme.colors.white,
                    width: "350px",
                  }}
                />
              )}
              {showSuccess && <SuccessMessage />}
              {showError && updateError && <ErrorMessage error={updateError} />}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
