import { TextStyles } from "../Theme";
import styled from "styled-components";
import { ISearchBarOverrideStyles, ISearchBarProps } from "../Components";

interface IProps {
  overrideStyles: ISearchBarOverrideStyles;
}

export const StyledSearchBarContainer = styled.div<IProps>`
  display: flex;
  align-items: center;
  position: ${({ overrideStyles }) => overrideStyles && overrideStyles.position};
  right: ${({ overrideStyles }) => overrideStyles && overrideStyles.right};
  width: ${({ overrideStyles }) => (overrideStyles ? overrideStyles.width : "260px")};
  height: ${({ overrideStyles }) => (overrideStyles ? overrideStyles.height : "35px")};
  margin-bottom: ${({ overrideStyles }) => (overrideStyles ? overrideStyles.marginBottom : "0px")};
  margin-top: ${({ overrideStyles }) => (overrideStyles ? overrideStyles.marginTop : "0px")};
  margin-left: ${({ overrideStyles }) => overrideStyles.marginLeft};
  border-radius: 10px;
  border-color: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderColor};
  border-style: solid;
  border-width: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderWidth};
  background-color: ${({ theme, overrideStyles }) => {
    return overrideStyles ? overrideStyles.backgroundColor : theme.colors.white;
  }};
`;

export const StyledSearchBarIconContainer = styled.div`
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-width: 1px;
  border-right-color: ${({ theme }) => theme.colors.lightGrey};
  border-right-style: solid;
`;

export const StyledSearchInput = styled.input.attrs({ type: "text" })<ISearchBarProps>`
  ${TextStyles.regular}
  font-weight: 400;
  background-color: ${({ theme, overrideStyles }) => {
    return overrideStyles ? overrideStyles.backgroundColor : theme.colors.white;
  }};
  height: 100%;
  width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  flex: 1;
  border: none;
  color: ${({ theme }) => theme.colors.mediumGrey};
  padding: 10px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGrey};
  }
`;

export const StyledTopPannelActionsContainer = styled.div`
  position: absolute;
  right: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledArrowAndTextContainer = styled.div`
  align-self: flex-start;
  ${TextStyles.regular}
  color: ${({ theme }) => theme.colors.mediumGrey};
  margin-left: 8px;
`;

export const StyledArrowContainer = styled.div`
  text-align: center;
`;

export const StyledArrowButton = styled.button`
  background-color: unset;
  margin: 0;
  border: none;
`;
