import { doc, updateDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { firestore, storage } from "../Firebase";
import { EventContext } from "../Context/EventContext";
import { logger } from "../Logger";
export const useRemoveEventImage = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const { onEventImageRemoved } = useContext(EventContext);
  const eventId = user?.uid!;
  const [isRemovingImage, setIsRemovingImage] = useState(false);
  const [hasJustRemovedImage, setHasJustRemovedImage] = useState(false);

  const deletePromises = (): Promise<void>[] => {
    const imagePaths = [`images/backgrounds/${eventId}`, `images/backgrounds/${eventId}-mobile`, `images/backgrounds/${eventId}-tablet`];
    return imagePaths.map((path) => {
      const imageRef = ref(storage, path);
      return deleteObject(imageRef);
    });
  };

  const removeImageFromEvent = useCallback(async (): Promise<void> => {
    try {
      setIsRemovingImage(true);
      await Promise.all(deletePromises());
      logger.log("Image successfully deleted from storage.");
      const eventDocRef = doc(firestore, "events", eventId);
      await updateDoc(eventDocRef, {
        imageUrl: null,
        mobileImageUrl: null,
        tabletImageUrl: null,
      });
      setHasJustRemovedImage(true);
      onEventImageRemoved();
      logger.log("Event document updated in Firestore.");
    } catch (error) {
      logger.error(error);
    } finally {
      setIsRemovingImage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);
  return {
    removeImageFromEvent,
    hasJustRemovedImage,
    isRemovingImage,
  };
};
