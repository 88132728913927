import { ITableNumberList } from "../../Selectors";
import { StyledAlphabeticalLetterContainer, StyledText, TableNumberContainer } from "../../Styles";

interface IProps {
  tableNumberAndList: ITableNumberList;
}

export const TableNumberAndGuests = ({ tableNumberAndList }: IProps) => {
  return (
    <StyledAlphabeticalLetterContainer>
      <TableNumberContainer>
        <StyledText textStyle="regular">{tableNumberAndList.tableNumberAsString}</StyledText>
      </TableNumberContainer>
      {tableNumberAndList.list.map(({ fullname }, index) => {
        return (
          <StyledText key={index} textStyle="regular" margin="0px 0px 10px 0px">
            {fullname}
          </StyledText>
        );
      })}
    </StyledAlphabeticalLetterContainer>
  );
};
