import { useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";

export const useMoveGuests = () => {
  const {
    state: { guestPaths },
    moveGuests,
  } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    moveGuests: () => {
      moveGuests(eventId, guestPaths);
    },
  };
};
