import { Link } from "react-router-dom";
import styled from "styled-components";
import { TextStyles } from "../Theme";
import { Form } from "formik";

export const StyledAuthContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;
export const StyledAuthFormContainer = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledAuthForm = styled(Form)`
  border-radius: 15px;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledWelcomeText = styled.div`
  ${TextStyles.regular};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-top: 20px;
`;

export const StyledRegularAuthText = styled.div`
  ${TextStyles.regular};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mediumGrey};
  margin-top: 10px;
`;

export const StyledErrorText = styled.div`
  ${TextStyles.regular};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 10px;
`;

export const StyledHaveAccountText = styled.div`
  ${TextStyles.regular};
  color: ${({ theme }) => theme.colors.mediumGrey};
`;

export const StyledAuthLink = styled(Link)`
  ${TextStyles.regular}
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 5px;
`;

export const AuthLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-top: 15px;
`;

export const ButtonAndFormErrorContainer = styled.div`
  margin-top: 20%;
  width: 100%;
`;
