import { useContext } from "react";
import { StyledEventDetailsContainer } from "../../Styles/EventDetails.styled";
import { EventLink } from "./EventLink";
import { EventQRCode } from "./EventQRCode";
import { EventStats } from "./EventStats";
import { StyledCenteredContainer, StyledMarginContainer, StyledSpinner } from "../../Styles";
import { Theme } from "../../Theme";
import { TableContext } from "../../Context/TableContext";
import { ExportEvent } from "./ExportEvent";
import { EventName } from "./EventName";
import { EventContext } from "../../Context/EventContext";

export const EventDetails = () => {
  const {
    state: { fetchTablesLoading },
  } = useContext(TableContext);
  const {
    state: { isFetchingEvent },
  } = useContext(EventContext);
  const margin = "0px 0px 70px 0px";
  if (isFetchingEvent || fetchTablesLoading) {
    return (
      <StyledCenteredContainer>
        <StyledSpinner size="60px" color={Theme.colors.darkBlue} />
      </StyledCenteredContainer>
    );
  }

  return (
    <StyledEventDetailsContainer>
      <div>
        <StyledMarginContainer margin={"0px 0px 10px 0px"}>
          <EventName />
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <EventStats />
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <ExportEvent />
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <EventLink />
        </StyledMarginContainer>
      </div>
      <div>
        <EventQRCode />
      </div>
    </StyledEventDetailsContainer>
  );
};
