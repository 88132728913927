import { FieldProps } from "formik";
import {
  StyledInputField,
  StyledInputFieldAndErrorDisplayContainer,
  StyledInputError,
  IOverrideTextInputStyles,
  StyledInputFieldLabel,
} from "../Styles";

type TOwnProps = {
  overrideStyles?: IOverrideTextInputStyles;
  type?: string;
  disabled?: boolean;
  error?: string;
  touched?: boolean;
  min?: number;
  isDuplicate?: boolean;
  placeholder?: string;
  label?: string;
  autoComplete?: string;
  onChange?(event: React.ChangeEvent<any>): void;
};

export type TTextInputFieldProps = TOwnProps & Partial<FieldProps>;

export const TextInputField = ({ overrideStyles = {}, type, min, placeholder, label, autoComplete, disabled, ...props }: TTextInputFieldProps) => {
  const showError = (props.touched && props.error) || props.error === "Duplicate";
  let inputFieldStyle = overrideStyles.inputFieldStyle;
  if (disabled && overrideStyles && overrideStyles.inputFieldStyle) {
    inputFieldStyle = { ...overrideStyles.inputFieldStyle, backgroundColor: "#e6e6e6" };
  }
  return (
    <StyledInputFieldAndErrorDisplayContainer inputFieldAndErrorDisplayContainerStyle={overrideStyles.inputFieldAndErrorDisplayContainerStyle}>
      {label && <StyledInputFieldLabel>{label}</StyledInputFieldLabel>}
      <StyledInputField
        inputFieldStyle={inputFieldStyle}
        type={type}
        {...props}
        min={min}
        placeholder={placeholder}
        autoComplete={autoComplete || "off"}
        disabled={disabled}
      />
      {showError && <StyledInputError inputErrorStyle={overrideStyles.inputErrorStyle}>{props.error}</StyledInputError>}
    </StyledInputFieldAndErrorDisplayContainer>
  );
};
