import { StyledProgressBar, StyledProgressBarContainer } from "../../Styles/EventDetails.styled";

interface IProps {
  percentage: number;
}
export const ProgressBar = ({ percentage }: IProps) => {
  return (
    <StyledProgressBarContainer>
      <StyledProgressBar percentage={percentage} />
    </StyledProgressBarContainer>
  );
};
