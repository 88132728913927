import { createSelector } from "reselect";
import { IEventReducerState } from "../Context/EventReducer";
import { isMobile, isTablet } from "react-device-detect";
const selectEventState = (state: IEventReducerState) => state;

export const selectEventName: (state: IEventReducerState) => string = createSelector(selectEventState, (state) => {
  return state.event?.name || "My Event";
});

export const selectEventImage: (state: IEventReducerState) => string | undefined = createSelector(selectEventState, (state) => {
  if (isMobile && state.event?.mobileImageUrl) {
    return state.event.mobileImageUrl;
  }
  if (isTablet && state.event?.tabletImageUrl) {
    return state.event.tabletImageUrl;
  }
  return state.event?.imageUrl;
});
