import { Theme } from "../Theme";
import { useContext } from "react";
import { SearchBar } from "./SearchBar";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { StyledArrowAndTextContainer, StyledArrowContainer, StyledTopPannelActionsContainer, StyledArrowButton, StyledText } from "../Styles";
import { Button } from "./Ui";
import { useMoveGuests, useSearchInput } from "../CustomHooks";
import { TableContext } from "../Context/TableContext";
import { selectSearchTextInfo, selectShouldShowSearchArrows, selectShouldShowTopPannelSearch } from "../Selectors";

const arrowStyle = { color: Theme.colors.darkBlue, width: 20, height: 20 };
interface IProps {
  showSearch: boolean;
}

export const TopPannelActions = ({ showSearch }: IProps) => {
  const { moveGuests } = useMoveGuests();
  const { state, onTopPannelSearched, nextSearchResultClicked, previousSearchResultClicked } = useContext(TableContext);
  const searchInfoText = selectSearchTextInfo(state);
  const { guestsNeedSaving, moveGuestsLoading } = state;
  const [onChange] = useSearchInput({
    onFinishedSearch: (searchText) => {
      onTopPannelSearched({ searchText });
    },
  });
  const showSearchArrows = selectShouldShowSearchArrows(state) && showSearch;
  const shouldShowSearch = selectShouldShowTopPannelSearch(state) && showSearch;
  return (
    <StyledTopPannelActionsContainer>
      {guestsNeedSaving && (
        <Button
          text={"Save Changes"}
          isLoading={moveGuestsLoading}
          onPress={moveGuests}
          spinnerColor={Theme.colors.darkBlue}
          buttonStyles={{
            padding: "10px",
            color: Theme.colors.darkBlue,
            borderRadius: "10px",
            marginRight: "30px",
            backgroundColor: Theme.colors.white,
            border: `1px solid ${Theme.colors.darkBlue}`,
          }}
        />
      )}
      {shouldShowSearch && (
        <SearchBar
          onChange={onChange}
          value={state.topPannelSearchText}
          overrideStyles={{
            backgroundColor: Theme.colors.lightGrey,
            borderColor: Theme.colors.mediumGrey,
            borderWidth: "0.25px",
            width: "224px",
            height: "35px",
          }}
          placeholder="Search guests"
        />
      )}
      {showSearchArrows && (
        <StyledArrowAndTextContainer>
          <StyledArrowContainer>
            <StyledArrowButton>
              <IoIosArrowUp style={arrowStyle} onClick={previousSearchResultClicked} />
            </StyledArrowButton>
            <StyledArrowButton>
              <IoIosArrowDown style={{ ...arrowStyle, marginLeft: 5, marginRight: 5 }} onClick={nextSearchResultClicked} />
            </StyledArrowButton>
          </StyledArrowContainer>
          <StyledText textStyle="regular" color={Theme.colors.blackGrey}>
            {`${searchInfoText?.indexOfCurrentMatch} of ${searchInfoText?.totalMatches}`} guests
          </StyledText>
        </StyledArrowAndTextContainer>
      )}
    </StyledTopPannelActionsContainer>
  );
};
