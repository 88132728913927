import { LayoutForInAppRoutes, DragDropInterface, ProtectedRoutes, Signup, Login, SeatingPlan } from ".";
import { SeatingPlanPreview } from "./DownloadSeatingPlan";
import { Routes, Route } from "react-router-dom";
import { DesktopOnlyComponent } from "./Ui/DesktopOnlyComponent";
import { RedirectToInAppRoutesIfAuthed } from "./RouteContainers/RedirectToInAppRoutesIfAuthed";
import { EventDetails } from "./EventDetails/EventDetails";
import { PaymentSuccessful } from "./PaymentSuccessful";
import { PaymentUnsuccesssful } from "./PaymentUnsuccesssful";
import { PasswordReset } from "./Auth/PasswordReset";
import { UserSettings } from "./ProfilePannel/UserSettings";

export const AppInterface = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route
          path="/"
          element={
            <DesktopOnlyComponent>
              <LayoutForInAppRoutes />
            </DesktopOnlyComponent>
          }
        >
          <Route path="organise" element={<DragDropInterface />} />
          <Route path="preview" element={<SeatingPlanPreview />} />
          <Route path="event-details" element={<EventDetails />} />
          <Route path="success" element={<PaymentSuccessful />} />
          <Route path="cancel" element={<PaymentUnsuccesssful />} />
          <Route path="profile" element={<UserSettings />} />
        </Route>
      </Route>
      <Route
        path="signup"
        element={
          <RedirectToInAppRoutesIfAuthed>
            <Signup />
          </RedirectToInAppRoutesIfAuthed>
        }
      />
      <Route
        path="signin"
        element={
          <RedirectToInAppRoutesIfAuthed>
            <Login />
          </RedirectToInAppRoutesIfAuthed>
        }
      />
      <Route path="seating-plan" element={<SeatingPlan />} />
      <Route
        path="reset-password"
        element={
          <RedirectToInAppRoutesIfAuthed>
            <PasswordReset />
          </RedirectToInAppRoutesIfAuthed>
        }
      />
    </Routes>
  );
};
