import styled from "styled-components";

interface IOverrideStyles {
  height?: string;
}

export const StyledLayoutForInAppRoutesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: ${({ theme }) => {
    return theme.colors.mediumLightGrey;
  }};
`;

export const StyledTopPannelAndOutletContainer = styled.div<IOverrideStyles>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({ height }) => height};
`;
