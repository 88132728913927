import { StyledLetterLink } from "../../Styles";
import { slide as Menu } from "react-burger-menu";

interface IProps {
  isOpen?: boolean;
  onStateChange(state: { isOpen: boolean }): void;
  onLetterClick(): void;
}

export const BurgerMenu = ({ isOpen, onStateChange, onLetterClick }: IProps) => {
  const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  return (
    <Menu isOpen={isOpen} onStateChange={onStateChange} width={"30%"}>
      {letters.map((letter) => (
        <StyledLetterLink onClick={onLetterClick} href={`#${letter.toLowerCase()}`}>
          {letter}
        </StyledLetterLink>
      ))}
    </Menu>
  );
};
