import { useContext, useRef, useCallback } from "react";
import { StyledFixedContentContainer, StyledSidePannelContainer, StyledSidePannelHeading, StyledSidePannelSubHeading } from "../Styles";
import { SearchBar, DroppableContainer, Button } from ".";
import { TableContext } from "../Context/TableContext";
import { useFullHeight, useSearchInput } from "../CustomHooks";
import { selectGuestsForSidePannel } from "../Selectors";
import { Theme } from "../Theme";
import { useUploadModalToggled } from "../CustomHooks/useUploadModalToggled";

export const SidePannel = () => {
  const sidePannelRef = useRef<any>();
  useFullHeight(sidePannelRef, 80);
  const { state, onSidePannelSearched } = useContext(TableContext);
  const { onUploadModalToggled } = useUploadModalToggled();

  const [onChange] = useSearchInput({
    onFinishedSearch: (searchText) => {
      onSidePannelSearched({ searchText });
    },
  });
  const guests = selectGuestsForSidePannel(state, state.sidePannelSearchText);
  const hasUploadedGuests = guests.length > 0;
  const showUploadButton = !hasUploadedGuests && !state.sidePannelSearchText;
  const showSearchBar = !showUploadButton;
  const instructionText = !showUploadButton
    ? "You can assign any guest to a particular table by dragging them to it."
    : "Guests must be uploaded before they can be assigned to a table.";

  const getHeight = useCallback(() => {
    return window.innerHeight;
  }, []);

  return (
    <StyledSidePannelContainer ref={sidePannelRef}>
      <StyledFixedContentContainer>
        <StyledSidePannelHeading>Drag guests to tables </StyledSidePannelHeading>
        <StyledSidePannelSubHeading>{instructionText}</StyledSidePannelSubHeading>
        {showSearchBar && (
          <SearchBar
            onChange={onChange}
            value={state.sidePannelSearchText}
            placeholder="Search guests"
            overrideStyles={{
              marginBottom: "30px",
              marginTop: "30px",
              height: "35px",
              width: "250px",
              marginLeft: "15px",
              backgroundColor: Theme.colors.white,
            }}
          />
        )}
        {showUploadButton && (
          <Button
            text="Upload guests"
            onPress={onUploadModalToggled}
            buttonStyles={{
              marginBottom: "30px",
              marginTop: "30px",
              height: "35px",
              width: "250px",
              marginLeft: "15px",
              borderRadius: "10px",
              backgroundColor: Theme.colors.white,
            }}
          />
        )}
      </StyledFixedContentContainer>
      <DroppableContainer droppableId="sidePannel" getHeight={getHeight} width={273} guests={guests} />
    </StyledSidePannelContainer>
  );
};
