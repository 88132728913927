import { ILogger } from ".";

export class DevLogger implements ILogger {
  log(message: string, ...params: any[]): void {
    if (params.length > 0) {
      console.log(message, ...params);
    } else {
      console.log(message);
    }
  }

  error(error: unknown, ...params: any[]): void {
    if (!(error instanceof Error)) {
      console.error("unknown error accoured");
      return;
    }
    console.error(error.message);
    if (error.stack) {
      console.error(error.stack);
    }
    if (params.length > 0) {
      console.error(...params);
    }
  }

  warn(message: string, ...params: any[]): void {
    if (params.length > 0) {
      console.warn(message, ...params);
    } else {
      console.warn(message);
    }
  }
}
