import { DragDropContext } from "react-beautiful-dnd";
import { useContext } from "react";
import { Board } from ".";
import { TableContext } from "../Context/TableContext";

export const DragDropInterface = () => {
  const { onDragEnd } = useContext(TableContext);

  return (
    <DragDropContext
      onDragEnd={(dropResult) => {
        onDragEnd({ dropResult });
      }}
    >
      <Board />
    </DragDropContext>
  );
};
