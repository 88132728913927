import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { EventContext } from "../Context/EventContext";

export const useAddEventName = () => {
  const { addEventName } = useContext(EventContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    addEventName: async (eventName: string) => {
      await addEventName(eventName, eventId);
    },
  };
};
