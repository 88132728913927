import { useContext } from "react";
import { IUpdateTableData, TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";

export const useUpdateTable = () => {
  const { updateTable } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    updateTable: async (tableInput: IUpdateTableData) => {
      await updateTable({ ...tableInput }, eventId);
    },
  };
};
