import { TextLoader } from "../../Styles";

export const LoadingText = () => {
  return (
    <TextLoader>
      <div className="loading">
        <span>S</span>
        <span>I</span>
        <span>M</span>
        <span>P</span>
        <span>L</span>
        <span>I</span>
        <span>F</span>
        <span>Y</span>
        <span>&nbsp;</span>
        <span>T</span>
        <span>A</span>
        <span>B</span>
        <span>L</span>
        <span>E</span>
        <span>S</span>
      </div>
    </TextLoader>
  );
};
