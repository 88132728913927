import { useState } from "react";
import { DropdownWrapper, OptionsList, Option, StyledDropdownButton, ArrowUp, ArrowDown } from "../../Styles/Dropdown.styled";

interface IProps {
  options: { label: string; value: any }[];
  selected: any | null;
  onSelect: (value: any) => void;
  defaultText: string;
}

export const Dropdown: React.FC<IProps> = ({ options, selected, onSelect, defaultText }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedLabel = options.find(({ value }) => value === selected)?.label;
  return (
    <DropdownWrapper>
      <StyledDropdownButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {selectedLabel || defaultText}
        {isOpen ? <ArrowUp /> : <ArrowDown />}
      </StyledDropdownButton>
      {isOpen && (
        <OptionsList>
          {options.map((option, index) => (
            <Option
              key={index}
              onClick={() => {
                onSelect(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </Option>
          ))}
        </OptionsList>
      )}
    </DropdownWrapper>
  );
};
