import { useCallback, useContext } from "react";
import { ModalContext } from "../Context/ModalContext";
import { AuthContext } from "../Context/AuthContext";

export const useTableModalToggled = () => {
  const { onTableModalToggled, onShowPaywall } = useContext(ModalContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  return {
    onTableModalToggled: useCallback(() => {
      if (!user?.hasPaid) {
        onShowPaywall();
      } else {
        onTableModalToggled();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  };
};
