import { useContext, useState } from "react";
import { TableContext } from "../../Context/TableContext";
import { SeatingPlanPreviewContainer, StyledSpinner, StyledCenteredContainer } from "../../Styles";
import { AlphabetTableNumberToggle } from "./AlphabetToggle";
import { Theme } from "../../Theme";
import { ListPreview } from "./ListPreview";

export interface IToggleButtonState {
  isTableNumberSelected: boolean;
  isAZSelected: boolean;
}

export const SeatingPlanPreview = () => {
  const { state } = useContext(TableContext);
  const [toggleButtonState, setToggleButtonState] = useState<IToggleButtonState>({ isTableNumberSelected: false, isAZSelected: true });
  const { isAZSelected, isTableNumberSelected } = toggleButtonState;
  const autoFillSpace = isAZSelected ? "350px" : "200px";
  const handleAZToggled = () => {
    setToggleButtonState({
      isAZSelected: true,
      isTableNumberSelected: false,
    });
  };

  const handleTableNumberToggled = () => {
    setToggleButtonState({
      isAZSelected: false,
      isTableNumberSelected: true,
    });
  };

  if (state.fetchTablesLoading) {
    return (
      <StyledCenteredContainer>
        <StyledSpinner size="60px" color={Theme.colors.darkBlue} />
      </StyledCenteredContainer>
    );
  }
  return (
    <SeatingPlanPreviewContainer>
      <div>
        <AlphabetTableNumberToggle
          toggleButtonState={toggleButtonState}
          handleAZToggled={handleAZToggled}
          handleTableNumberToggled={handleTableNumberToggled}
        />
      </div>
      <ListPreview autoFillSpace={autoFillSpace} isAZSelected={isAZSelected} isTableNumberSelected={isTableNumberSelected} />
    </SeatingPlanPreviewContainer>
  );
};
