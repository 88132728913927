import React, { ReactElement } from "react";
import { isEqual } from "lodash";
import { StyledButtonAndLabelContainer, StyledButtonLabel } from "../../Styles";
import { CSSTransition } from "react-transition-group";

interface IOverrideStyle {
  position?: string;
  bottom?: string;
  whiteSpace?: string;
}

interface IProps {
  isOpen: boolean;
  label: string | JSX.Element;
  Component: ReactElement;
  componentRef: any;
  overrideStyle?: IOverrideStyle;
  onClick?: () => void;
}
const ButtonAndLabel = React.memo(({ isOpen, Component, componentRef, label, overrideStyle = {}, onClick }: IProps) => {
  const { position, bottom, whiteSpace = "nowrap" } = overrideStyle;
  return (
    <StyledButtonAndLabelContainer marginTop="15px" position={position} bottom={bottom}>
      {Component}
      <CSSTransition nodeRef={componentRef} in={isOpen} timeout={200} classNames={"fade"} unmountOnExit>
        <StyledButtonLabel whiteSpace={whiteSpace} ref={componentRef} isOpen={isOpen} onClick={onClick}>
          {label}
        </StyledButtonLabel>
      </CSSTransition>
    </StyledButtonAndLabelContainer>
  );
}, isEqual);
export default ButtonAndLabel;
