import styled from "styled-components";

interface IProps {
  size: string;
  color: string;
  margin?: string;
}

export const StyledSpinner = styled.div<IProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin: ${({ margin }) => margin};
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: ${({ color }) => color};
  border-bottom-color: ${({ color }) => color};
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
