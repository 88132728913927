import styled from "styled-components";
import { TextStyles } from "../Theme";

export const StyledCenteredContainer = styled.div<{
  boxShadow?: string;
  boderRadius?: string;
  width?: string;
  height?: string;
  flexDirection?: string;
  padding?: string;
}>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: ${({ boderRadius }) => boderRadius};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  flex-direction: ${({ flexDirection }) => flexDirection};
`;
export const StyledHeading = styled.h1<{ color?: string }>`
  ${TextStyles.regular};
  text-align: center;
  color: ${({ color }) => color};
`;

export const StyledOverlay = styled.div<{ backgroundColor: string; overflow?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: ${({ overflow }) => overflow};
`;

export const FixedSizeContainer = styled.div<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
`;

export const Flex1Container = styled.div`
  flex: 1;
`;

export const FlexAlignCenterContainer = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const FlexJustifyEndContainer = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: end;
  flex: 1;
  margin: ${({ margin }) => margin};
`;

export const StyledText = styled.p<{
  textStyle: string;
  color?: string;
  margin?: string;
  textAlign?: string;
  lineHeight?: string;
  fontWeight?: string;
}>`
  ${({ textStyle }) => TextStyles[textStyle]}
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const AlignColumnItemsCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
`;

export const SpaceBetweenContainer = styled.div<{ width: string; margin?: string }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;

const sizes = {
  mobile: "500px",
  tablet: "900px",
  desktop: "1200px",
};

export const breakpoints = {
  mobile: `(max-width: ${sizes.mobile})`,
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

export const StyledMarginContainer = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
  width: max-content;
`;

export const StyledLink = styled.a<{ textStyle: string; display?: string }>`
  ${({ textStyle }) => TextStyles[textStyle]}
  color: ${({ theme }) => theme.colors.darkBlue};
  &:visited {
    color: ${({ theme }) => theme.colors.darkBlue}; // ensure the color remains blue even after being visited
  }
  display: ${({ display }) => display};
`;

export const StyledRowContainer = styled.div<{ width: string; margin: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
`;
