import { useContext, useEffect } from "react";
import { TableContext } from "../../Context/TableContext";
import { StyledFormStepExplanation } from "../../Styles";
import { Theme } from "../../Theme";
import { logger } from "../../Logger";

interface IProps {
  handleFileUpload(): void;
}

export const CompleteUpload = ({ handleFileUpload }: IProps) => {
  const {
    state: { uploadGuestsError, reasonForRowsNotProcessedAfterUpload },
    clearUploadMessage,
  } = useContext(TableContext);
  useEffect(() => {
    logger.log("calling useEffect from CompleteUpload component");
    handleFileUpload();
    return () => {
      clearUploadMessage();
    };
  }, [clearUploadMessage, handleFileUpload]);
  return (
    <>
      {uploadGuestsError && <StyledFormStepExplanation color={Theme.colors.maroon}>{uploadGuestsError}</StyledFormStepExplanation>}
      {!uploadGuestsError && <StyledFormStepExplanation>Upload successful!</StyledFormStepExplanation>}
      {reasonForRowsNotProcessedAfterUpload && <StyledFormStepExplanation>{reasonForRowsNotProcessedAfterUpload}</StyledFormStepExplanation>}
    </>
  );
};
