import { useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";
import { TableInput } from "../Context/tableReducer";

export const useAddTables = () => {
  const { addTables } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    addTables: async (tableInput: TableInput[]) => {
      await addTables(tableInput, eventId);
    },
  };
};
