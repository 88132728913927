import { DevLogger } from "./DevLogger";
import { ProdLogger } from "./ProdLogger";

export interface ILogger {
  log: (message: string, ...params: any[]) => void;
  error: (error: unknown, ...params: any[]) => void;
  warn: (message: string, ...params: any[]) => void;
}

const isDevEnvironment = process.env.NODE_ENV === "development";
export const logger: ILogger = isDevEnvironment ? new DevLogger() : new ProdLogger();
