import { useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";

export const useDeleteTable = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const { deleteTable } = useContext(TableContext);
  const eventId = user?.uid!;
  return {
    deleteTable: async () => {
      await deleteTable(eventId);
    },
  };
};
