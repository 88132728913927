import React, { CSSProperties } from "react";
import { StyledTopPannel, StyledTopPannelMenuItem } from "../Styles";
import { NavLink, useLocation } from "react-router-dom";
import { TopPannelActions } from "./TopPannelActions";
import { logger } from "../Logger";

const getNavLinkStyle = (): CSSProperties => {
  return {
    textDecoration: "none",
  };
};

const renderDashboardLinks = () => {
  return (
    <>
      <NavLink to="/organise" style={getNavLinkStyle}>
        {({ isActive }) => {
          return <StyledTopPannelMenuItem isActive={isActive}>Organise guests</StyledTopPannelMenuItem>;
        }}
      </NavLink>
      <NavLink to="/preview" style={getNavLinkStyle}>
        {({ isActive }) => {
          return <StyledTopPannelMenuItem isActive={isActive}>Preview Seating Plan</StyledTopPannelMenuItem>;
        }}
      </NavLink>
      <NavLink to="/event-details" style={getNavLinkStyle}>
        {({ isActive }) => {
          return <StyledTopPannelMenuItem isActive={isActive}>Event Overview & QR Code</StyledTopPannelMenuItem>;
        }}
      </NavLink>
    </>
  );
};

const renderBackToDashboardLink = () => {
  return (
    <>
      <NavLink to="/organise" style={getNavLinkStyle}>
        {({ isActive }) => {
          return <StyledTopPannelMenuItem isActive={false}>Dashboard</StyledTopPannelMenuItem>;
        }}
      </NavLink>
    </>
  );
};

export const TopPannel = React.memo(() => {
  logger.log("rendering TopPannel");
  const location = useLocation();
  const showSearch = location.pathname === "/organise";
  const showDashboardLinks = location.pathname !== "/profile";
  const showBackToDashboardLink = location.pathname === "/profile";
  return (
    <StyledTopPannel>
      {showDashboardLinks && renderDashboardLinks()}
      {showBackToDashboardLink && renderBackToDashboardLink()}
      <TopPannelActions showSearch={showSearch} />
    </StyledTopPannel>
  );
});
