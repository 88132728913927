import styled from "styled-components";
import { TextStyles } from "../Theme";

interface IProps {
  isOpen?: boolean;
  marginTop?: string;
  position?: string;
  bottom?: string;
  whiteSpace?: string;
}

export const StyledProfilePannelContainer = styled.div<IProps>`
  width: ${({ isOpen }) => (isOpen ? "195px" : "80px")};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: 3px 0px 28px rgba(0, 0, 0, 0.1);
  transition: 250ms ease-in;
  position: sticky;
  top: 0px;
  z-index: 300;
`;

export const StyledCollapseArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.offWhite};
  color: ${({ theme }) => theme.colors.darkBlue};
  position: absolute;
  right: -15px;
  top: 25px;
  z-index: 400;
`;

export const StyledButtonLabel = styled.div<IProps>`
  margin-left: 10px;
  white-space: ${({ whiteSpace }) => {
    return whiteSpace;
  }};
  ${TextStyles.regular}
  color: ${({ theme }) => theme.colors.darkBlue};
  flex-shrink: 1;
  max-width: 95px;
  &:active {
    opacity: 0.5;
  }
`;

export const StyledButtonAndLabelContainer = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin-top: ${({ marginTop }) => marginTop};
  position: ${({ position }) => position};
  bottom: ${({ bottom }) => bottom};
  height: 60px;
  cursor: pointer;
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey};
  height: 1px;
  opacity: 0.3;
  margin-bottom: 20px;
  margin-top: 4px;
`;
