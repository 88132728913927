import { useEffect, useState } from "react";
import {
  StyledRadioOuterCircle,
  StyledRadioInnerCircle,
  StyledRadioButtonAndLabelContainer,
  StyledLabel,
  StyledRadioButtonsDescription,
  StyledLabelAndDescriptonContainer,
} from "../../Styles";

interface IRadioButtonProps {
  isActive: boolean;
  label: string;
  hasMargin: boolean;
  description?: string;
  onClick: () => void;
}
export const RadioButton = ({ isActive, label, onClick, description, hasMargin }: IRadioButtonProps) => {
  const marginBottom = hasMargin ? "27px" : "0px";
  return (
    <StyledRadioButtonAndLabelContainer onClick={onClick} marginBottom={marginBottom}>
      <StyledRadioOuterCircle>{isActive && <StyledRadioInnerCircle />}</StyledRadioOuterCircle>
      <StyledLabelAndDescriptonContainer>
        <StyledLabel>{label}</StyledLabel>
        <StyledRadioButtonsDescription>{description}</StyledRadioButtonsDescription>
      </StyledLabelAndDescriptonContainer>
    </StyledRadioButtonAndLabelContainer>
  );
};

interface IRadioButtonItem {
  label: string;
  description?: string;
  value: any;
}

interface IRadioButtonsProps {
  items: IRadioButtonItem[];
  callback?: (value: any) => void;
}

export const RadioButtons = ({ items, callback }: IRadioButtonsProps) => {
  const [activeValue, setActiveValue] = useState<any>(null);
  useEffect(() => {
    if (callback) {
      callback(activeValue);
    }
  }, [activeValue, callback]);
  return (
    <>
      {items.map((item, index) => {
        return (
          <RadioButton
            hasMargin={!index}
            key={index}
            isActive={item.value === activeValue}
            label={item.label}
            description={item.description}
            onClick={() => {
              setActiveValue(item.value);
            }}
          />
        );
      })}
    </>
  );
};
