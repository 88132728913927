import styled from "styled-components";
import { TextStyles } from "../Theme";

interface IProps {
  isActive: boolean;
}

export const StyledTopPannel = styled.div`
  min-height: 80px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  width: 100%;
  display: flex;
  position: sticky;
  top: 0px;
  z-index: 200;
  justify-content: flex-start;
  align-items: center;
  padding-right: 30px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
  /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07); */
`;

export const StyledTopPannelMenuItem = styled.span<IProps>`
  ${TextStyles.regular};
  position: relative;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 30px;
  font-weight: ${({ isActive }) => {
    return isActive ? 500 : 400;
  }};
  &::after {
    content: "";
    background: ${({ theme }) => theme.colors.darkBlue};
    position: absolute;
    bottom: -5px;
    left: 30%;
    width: 40%;
    transition: 100ms;
    height: ${({ isActive }) => (isActive ? "2.5px" : "0px")};
  }
`;
