import { StyledText } from "../../Styles";
import { Theme } from "../../Theme";
import { FaPen } from "react-icons/fa";
import { StyledEventNameContainer } from "../../Styles/EventDetails.styled";
import { useEventNameModalToggled } from "../../CustomHooks/useEventNameModalToggled";
import { useContext } from "react";
import { EventContext } from "../../Context/EventContext";
import { selectEventName } from "../../Selectors/eventSelectors";

export const EventName = () => {
  const { onEventNameModalToggled } = useEventNameModalToggled();
  const { state } = useContext(EventContext);
  const name = selectEventName(state);
  const canEditEvent = !state.event?.name;
  const cursorStyle = canEditEvent ? "pointer" : "default";
  return (
    <StyledEventNameContainer onClick={onEventNameModalToggled} cursorStyle={cursorStyle}>
      <StyledText textStyle="heading" color={Theme.colors.darkBlue} fontWeight="500">
        {name}
      </StyledText>
      {canEditEvent && (
        <FaPen
          style={{
            color: Theme.colors.darkBlue,
            margin: "0px 0px 15px 5px",
          }}
        />
      )}
    </StyledEventNameContainer>
  );
};
