import { useContext } from "react";
import { IUpdateGuestData, TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";

export const useUpdateGuest = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  const {
    updateGuest,
    state: { guestOpenInModal, tableOfguestOpenInModal, guestPaths },
  } = useContext(TableContext);
  const guestHasNotBeenMoved = !guestPaths[guestOpenInModal];
  const updateGuestTable = guestHasNotBeenMoved ? tableOfguestOpenInModal : guestPaths[guestOpenInModal][0];
  return {
    updateGuest: async (args: IUpdateGuestData, newGuestId: string) => {
      await updateGuest({
        ...args,
        newGuestId,
        eventId,
        guestId: guestOpenInModal,
        tableIdToUpdateInDB: updateGuestTable,
        tableId: tableOfguestOpenInModal,
      });
    },
  };
};
