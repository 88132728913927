import { useRef, useContext } from "react";
import {
  StyledAddNewTableRowButton,
  StyledOverflowContainer,
  StyledButton,
  StyledReactToolTip,
  StyledGridContainer,
  StyledColumnHeadingsContainer,
  StyledSaveTableButtonContainer,
} from "../../Styles";
import { TableContext } from "../../Context/TableContext";
import { TableInput } from "../../Context/tableReducer";
import { Theme } from "../../Theme";
import { AiOutlinePlusCircle, AiFillDelete } from "react-icons/ai";
import { Button, TextInputField } from "../";
import { Formik, FieldArray, FastField, Form, FieldProps, FormikErrors } from "formik";
import { TableHeading } from "./TableHeading";
import { useAddTables } from "../../CustomHooks/useAddTables";
import { selectTableNumbers } from "../../Selectors";

interface ITable {
  capacity: number | string;
  tableNumber: number | string;
  tableName: string;
}
interface ITableInputFormProps {
  closeModal: () => void;
}

export interface TableInputFormValues {
  tables: ITable[];
}

const saveAndCancelButtonStyle = { width: "130px", height: "46px", borderRadius: "15px" };
const getButtonDisabled = (errors: FormikErrors<TableInputFormValues>) => {
  if (errors && errors.tables && errors.tables.length > 0) {
    return true;
  }
  return false;
};

const inputFieldAndErrorDisplayContainerStyle = { display: "block", marginRight: "30px" };

const inputFieldStyle = {
  height: "60%",
};

const inputErrorStyle = {
  padding: "0px 10px 0px 0px",
  fontSize: "12px",
};

export const TableInputForm = ({ closeModal }: ITableInputFormProps) => {
  const { state } = useContext(TableContext);
  const tableNumbers = selectTableNumbers(state);
  const isLoading = state.addingTable;
  const { addTables } = useAddTables();
  const timeOutId = useRef<any>();
  const validationPromise = (tables: ITable[]) => {
    return new Promise((resolve) => {
      clearTimeout(timeOutId.current);
      const timer = setTimeout(() => {
        let namesUntilIndex: string[] = [];
        resolve({
          tables: tables.map((table: any) => {
            const tableNumber = table.tableNumber;
            const error = { tableNumber: "", capacity: "", tableName: "" };
            const alreadyAddedTable = tableNumbers.includes(tableNumber);
            if (!tableNumber) {
              error.tableNumber = "Required";
            }
            if (table.tableNumber < 1 && tableNumber) {
              error.tableNumber = "Must be at least one";
            }
            if (alreadyAddedTable) {
              error.tableNumber = "Duplicate";
            }
            if (tableNumber > 0 && namesUntilIndex.includes(tableNumber) && !alreadyAddedTable) {
              error.tableNumber = "Duplicate";
            }
            if (table.capacity < 1) {
              error.capacity = "Must be at least 1";
            }
            namesUntilIndex.push(tableNumber);
            if (error.tableNumber === "" && error.capacity === "") {
              return null;
            }
            return error;
          }),
        });
      }, 0.1);
      timeOutId.current = timer;
    });
  };
  return (
    <Formik<TableInputFormValues>
      validateOnChange={true}
      validateOnMount={true}
      initialValues={{
        tables: Array.from({ length: 1 }, (v, i) => {
          return {
            tableNumber: "",
            tableName: "",
            capacity: "",
          };
        }),
      }}
      validate={({ tables }): Promise<FormikErrors<TableInputFormValues>> => {
        return validationPromise(tables).then((value: any) => {
          if (value.tables.filter(Boolean).length === 0) {
            return {};
          }
          return value;
        });
      }}
      onSubmit={async (values) => {
        const tableInput = values.tables as TableInput[];
        await addTables(tableInput);
        closeModal();
        return;
      }}
    >
      {({ errors, values, dirty }) => {
        return (
          <Form>
            <StyledColumnHeadingsContainer>
              <TableHeading
                heading="Table Number"
                toolTip="This will be displayed on the seating plan.<br/>Make sure to follow the seating layout of your venue."
              />
              <TableHeading heading="Capacity" toolTip="This is the number of seats at a table" />
              <TableHeading
                heading="Table Name"
                toolTip="This is to help you organize your guests<br/>This will not be displayed on the seating plan."
              />
              <StyledReactToolTip id="main" place="top" type="dark" multiline={true} />
            </StyledColumnHeadingsContainer>
            <FieldArray name="tables" validateOnChange={true}>
              {({ push, remove }) => {
                const currentInput = values.tables[values.tables.length - 1];
                const { tableNumber: currentTableNumber, capacity: currentCapacity } = currentInput;
                const hasEnteredTableNumber = typeof currentTableNumber === "number";
                const hasEnteredCapacity = typeof currentCapacity === "number";
                const nextTableNumber = hasEnteredTableNumber ? currentTableNumber + 1 : "";
                const nextTableCapacity = hasEnteredCapacity ? currentCapacity : "";
                return (
                  <>
                    <StyledOverflowContainer>
                      {values.tables.map((_, index) => {
                        return (
                          <StyledGridContainer key={index}>
                            <FastField name={`tables[${index}].tableNumber`}>
                              {({ field, meta }: FieldProps) => {
                                return (
                                  <TextInputField
                                    {...field}
                                    {...meta}
                                    type="number"
                                    min={1}
                                    overrideStyles={{ inputFieldStyle, inputFieldAndErrorDisplayContainerStyle, inputErrorStyle }}
                                  />
                                );
                              }}
                            </FastField>

                            <FastField name={`tables[${index}].capacity`}>
                              {({ field, meta }: FieldProps) => {
                                return (
                                  <TextInputField
                                    {...meta}
                                    {...field}
                                    type="number"
                                    min={1}
                                    overrideStyles={{ inputFieldStyle, inputFieldAndErrorDisplayContainerStyle, inputErrorStyle }}
                                  />
                                );
                              }}
                            </FastField>
                            <FastField name={`tables[${index}].tableName`}>
                              {({ field, meta }: FieldProps) => {
                                return <TextInputField {...meta} {...field} type="text" overrideStyles={{ inputFieldStyle: { height: "60%" } }} />;
                              }}
                            </FastField>
                            {values.tables.length > 1 && (
                              <AiFillDelete
                                style={{ height: "30", width: "30", alignSelf: "flex-start", justifySelf: "center" }}
                                onClick={() => {
                                  const indexToRemove = values.tables.findIndex((_, i) => i === index);
                                  remove(indexToRemove);
                                }}
                              />
                            )}
                          </StyledGridContainer>
                        );
                      })}
                    </StyledOverflowContainer>
                    <StyledAddNewTableRowButton
                      type="button"
                      onClick={() => {
                        push({
                          tableName: "",
                          tableNumber: nextTableNumber,
                          capacity: nextTableCapacity,
                        });
                      }}
                    >
                      <AiOutlinePlusCircle style={{ width: 25, height: 25, color: Theme.colors.darkBlue }} />
                      Add Table
                    </StyledAddNewTableRowButton>
                  </>
                );
              }}
            </FieldArray>
            <StyledSaveTableButtonContainer>
              <StyledButton
                type="submit"
                overrideStyles={{
                  ...saveAndCancelButtonStyle,
                  backgroundColor: Theme.colors.white,
                  color: Theme.colors.darkBlue,
                  border: `1px solid ${Theme.colors.darkBlue}`,
                  marginRight: "15px",
                }}
                onClick={closeModal}
              >
                Cancel
              </StyledButton>
              <Button
                type="submit"
                text="Save"
                isLoading={isLoading}
                buttonStyles={{
                  ...saveAndCancelButtonStyle,
                  color: Theme.colors.white,
                }}
                disabled={getButtonDisabled(errors) && !dirty}
              />
            </StyledSaveTableButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
