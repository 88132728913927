import { RadioButtons } from "..";
import { GuestFormat } from "../../Context/tableReducer";
import { StyledFormStepExplanation } from "../../Styles";

interface IProps {
  formatSelected: (format: GuestFormat) => void;
}

export const ChooseFormat = ({ formatSelected }: IProps) => {
  return (
    <>
      <StyledFormStepExplanation>Select the format you prefer for displaying your guests' names on the seating plan.</StyledFormStepExplanation>
      <RadioButtons
        items={[
          { label: "[Title] [Initial] [Surname]", value: GuestFormat.Format1, description: "E.g. Mrs R Whitington" },
          { label: "[First Name] [Last Name]", value: GuestFormat.Format2, description: "Raylene Whitington" },
        ]}
        callback={formatSelected}
      />
    </>
  );
};
