import { ReactElement } from "react";
import { IoverrideButtonStyles, StyledButton, StyledSpinner } from "../../Styles";
import { Theme } from "../../Theme";

export interface IButtonProps {
  text: string | ReactElement;
  isLoading?: boolean;
  buttonStyles?: IoverrideButtonStyles;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  Icon?(): ReactElement;
  isJumping?: boolean;
  spinnerColor?: string;
  onPress?: () => void;
}
export const Button = ({
  isLoading = false,
  text,
  Icon = () => <></>,
  buttonStyles,
  type,
  disabled = false,
  onPress,
  isJumping,
  spinnerColor = Theme.colors.white,
}: IButtonProps) => {
  return (
    <StyledButton type={type} overrideStyles={buttonStyles} disabled={disabled || isLoading} onClick={onPress} jumping={isJumping}>
      {isLoading ? (
        <StyledSpinner size="20px" color={spinnerColor} />
      ) : (
        <>
          {text}
          <Icon />
        </>
      )}
    </StyledButton>
  );
};
