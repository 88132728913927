import React, { useContext, useState } from "react";
import { CSSProperties } from "react";
import { StyledSingleGuestDisplayContainer, StyledColoredLetter, StyledInitialsCircle, CloseIconContainer, StyledNameContainer } from "../Styles";
import { DraggableProvided } from "react-beautiful-dnd";
import { TGuest } from "../Context/tableReducer";
import { Theme } from "../Theme";
import { isEqual } from "lodash";
import { TableContext } from "../Context/TableContext";
import { topPannelSearchTextSelector } from "../Selectors";
import { IoIosClose } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import { formatGuestFullname, formatGuestInitials, sanitizeString, formatGuestnameForSearch } from "../Utils";
import { useDeleteGuest } from "../CustomHooks/useDeleteGuest";
import { FaPen } from "react-icons/fa";
import { ModalContext } from "../Context/ModalContext";

export interface ISingleGuestDisplayProps {
  hasBorderBottom?: boolean;
  provided: DraggableProvided;
  guest: TGuest;
  style?: CSSProperties;
  isDragging: boolean;
  tableRowToScrollTo?: number;
  droppableId: string;
}

interface IColoredNameProps {
  name: string;
  displayName: string;
  topPannelSearchText: string | undefined;
}
const ColoredName = ({ name, topPannelSearchText, displayName }: IColoredNameProps) => {
  if (!topPannelSearchText) {
    return <>{displayName}</>;
  }
  const sanitizedSearchText = sanitizeString(topPannelSearchText);
  const substringIndex = name.indexOf(sanitizedSearchText);
  return (
    <>
      {Array.from({ length: name.length }).map((_, index) => {
        if (sanitizedSearchText && index >= substringIndex && index < substringIndex + sanitizedSearchText.length) {
          return <StyledColoredLetter key={index}>{displayName.charAt(index)}</StyledColoredLetter>;
        }
        return <pre key={index}>{displayName.charAt(index)}</pre>;
      })}
    </>
  );
};

const getStyle = (provided: DraggableProvided, style: CSSProperties | undefined, isDragging: boolean) => {
  return {
    ...style,
    ...provided.draggableProps.style,
    backgroundColor: isDragging ? Theme.colors.lightBlue : "",
  };
};

export const SingleGuestDisplay = React.memo(
  ({ guest, hasBorderBottom, provided, style, isDragging, tableRowToScrollTo, droppableId }: ISingleGuestDisplayProps) => {
    const { state, removeGuestFromTable, onGuestModalOpened: onEditGuestModalOpenedAction } = useContext(TableContext);
    const { onEditGuestModalOpened } = useContext(ModalContext);
    const { deleteGuest } = useDeleteGuest();
    const topPannelSearchText = topPannelSearchTextSelector(state);
    const fullname = formatGuestFullname(guest);
    const initials = formatGuestInitials(guest);
    const [hovering, setHovering] = useState(false);
    const showCloseIcon = hovering && droppableId !== "sidePannel";
    const showDeleteIcon = hovering && droppableId === "sidePannel";
    const showEditIcon = hovering;
    const iconStyle = { width: 20, height: 20, cursor: "pointer" };
    return (
      <StyledSingleGuestDisplayContainer
        hasBorderBottom={hasBorderBottom}
        isDragging={isDragging}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={getStyle(provided, style, isDragging)}
        onMouseOver={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
      >
        <StyledInitialsCircle
          onClick={() => {
            onEditGuestModalOpenedAction({ guestId: guest.id, tableId: droppableId });
            onEditGuestModalOpened();
          }}
        >
          {initials}
          {showEditIcon && (
            <FaPen
              style={{
                color: Theme.colors.darkBlue,
                width: 10,
                height: 10,
                top: "-5%",
                right: "-5%",
                cursor: "pointer",
                position: "absolute",
              }}
            />
          )}
        </StyledInitialsCircle>
        {!tableRowToScrollTo && <StyledNameContainer>{fullname}</StyledNameContainer>}
        {tableRowToScrollTo && (
          <ColoredName displayName={fullname} name={formatGuestnameForSearch(guest)} topPannelSearchText={topPannelSearchText} />
        )}
        {showCloseIcon && (
          <CloseIconContainer>
            <IoIosClose
              style={iconStyle}
              onClick={() => {
                removeGuestFromTable({ tableId: droppableId, guestId: guest.id });
              }}
            />
          </CloseIconContainer>
        )}
        {showDeleteIcon && (
          <CloseIconContainer>
            <AiFillDelete
              style={iconStyle}
              onClick={() => {
                deleteGuest(guest.id);
              }}
            />
          </CloseIconContainer>
        )}
      </StyledSingleGuestDisplayContainer>
    );
  },
  isEqual
);
