import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { StyledSpinner } from "../../Styles";
import { Theme } from "../../Theme";

export const ProtectedRoutes = () => {
  const { state } = useContext(AuthContext);
  const { user, authPending } = state;
  if (authPending) {
    return <StyledSpinner size="60px" color={Theme.colors.darkBlue} margin={"auto"} />;
  }
  if (!user) {
    return <Navigate to="/signin" />;
  }
  return <Outlet />;
};
