import { useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { StyledBoardContainer, StyledBoard, StyledSpinner, StyledCenteredContainer } from "../Styles";
import { Table } from ".";
import { selectTableRowToScrollTo, selectTables, selectGuestsForTable, selectShouldScrollIntoView, selectShouldShowDropZone } from "../Selectors";
import { SidePannel } from "./SidePannel";
import { Theme } from "../Theme";

export const Board = () => {
  const { state } = useContext(TableContext);
  const tables = selectTables(state);
  const tableRowToScrollTo = selectTableRowToScrollTo(state);
  if (state.fetchTablesLoading) {
    return (
      <StyledCenteredContainer>
        <StyledSpinner size="60px" color={Theme.colors.darkBlue} />
      </StyledCenteredContainer>
    );
  }
  return (
    <StyledBoardContainer>
      <SidePannel />
      <StyledBoard>
        {Object.entries(tables).map((entry: any) => {
          const [key, table] = entry;
          const guests = selectGuestsForTable(state, table.tableId);
          const scrollIntoView = selectShouldScrollIntoView(state, table.tableId);
          const showDropZone = selectShouldShowDropZone(state, key);
          return (
            <Table
              capacity={table.capacity}
              tableName={table.tableName}
              key={key}
              droppableId={key}
              guests={guests}
              tableNumber={table.tableNumber}
              tableRowToScrollTo={tableRowToScrollTo}
              scrollIntoView={scrollIntoView}
              showDropZone={showDropZone}
            ></Table>
          );
        })}
      </StyledBoard>
    </StyledBoardContainer>
  );
};
