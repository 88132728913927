import styled from "styled-components";
import { TextStyles } from "../Theme";

interface IProgressStepProps {
  isActive: boolean;
  isComplete: boolean;
  size: number;
}

interface IProgressStepAndLabelContainer {
  marginLeft: string;
}

interface IProgressLineProps {
  width?: string;
}

interface IProgressContainerProps {
  size: number;
}

export const ProgressContainer = styled.div<IProgressContainerProps>`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0px auto 100px auto;
  counter-reset: step;
  height: ${({ size }) => size + "px"};
  width: 500px;
  &::before {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: ${({ theme }) => {
      return theme.colors.grey;
    }};
    top: 50%;
    transform: translateY(-50%);
    transition: 0.7s;
  }
`;

export const ProgresLine = styled.div<IProgressLineProps>`
  height: 2px;
  width: ${({ width }) => width};
  position: absolute;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  top: 50%;
  transform: translateY(-50%);
  transition: 0.7s;
`;

export const ProgressStep = styled.div<IProgressStepProps>`
  width: ${({ size }) => size + "px"};
  height: ${({ size }) => size + "px"};
  border-radius: 50%;
  counter-increment: step;
  background-color: ${({ isComplete, theme, isActive }) => {
    if (isComplete) {
      return theme.colors.darkBlue;
    }
    if (isActive) {
      return theme.colors.white;
    }
    return theme.colors.grey;
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.colors.darkBlue}` : "none")};
  &::before {
    ${TextStyles.regular};
    content: counter(step);
    color: ${({ isComplete, theme }) => (isComplete ? theme.colors.white : theme.colors.blackGrey)};
  }
`;

export const ProgressStepAndLabelContainer = styled.div<IProgressStepAndLabelContainer>`
  position: absolute;
  display: flex;
  justify-content: center;
  margin-left: ${({ marginLeft }) => marginLeft};
`;

export const ProgressLabel = styled.label`
  position: absolute;
  ${TextStyles.regular};
  top: calc(100% + 14px);
  text-align: center;
  color: ${({ theme }) => theme.colors.blackGrey};
`;
