import { AiOutlineInfoCircle } from "react-icons/ai";
import { StyledInfoContainer } from "../../Styles/Info.styled";
import { StyledText } from "../../Styles";

interface IIConStyle {
  width: number;
  color: string;
  height: number;
  marginRight?: number;
}
interface IContainerStyle {
  margin: string;
}
interface IProps {
  text: string;
  textStyle: string;
  iconStyle: IIConStyle;
  containerStyle: IContainerStyle | {};
}
export const Info = ({ text, textStyle, iconStyle, containerStyle }: IProps) => {
  return (
    <StyledInfoContainer {...containerStyle}>
      <AiOutlineInfoCircle style={iconStyle} />
      <StyledText textStyle={textStyle}>{text}</StyledText>
    </StyledInfoContainer>
  );
};
