import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../Firebase";
import { addEventImage } from "../Firebase/firestore";
import { EventContext } from "../Context/EventContext";
import imageCompression from "browser-image-compression";

export const useUploadBackgorundImage = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isCompressingFile, setIsCompressingFile] = useState(false);
  const [uploadError, setUploadError] = useState<null | string>(null);
  const { onEventImageAdded } = useContext(EventContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;

  const handleUpload = useCallback(
    async (imageFile?: File) => {
      const fileOptions = {
        maxSizeMB: 1, // (max file size in MB)
        maxWidthOrHeight: 1920, // (max width or height in pixels)
        useWebWorker: true,
      };
      let compressedFile: File;
      if (!imageFile) {
        return {};
      }
      try {
        setIsCompressingFile(true);
        compressedFile = await imageCompression(imageFile, fileOptions);
        setIsCompressingFile(false);
      } catch (e: any) {
        console.error(e);
        setIsCompressingFile(false);
        setUploadError(`Upload failed: ${e.message}`);
        return;
      }
      setIsUploading(true);
      const imagePath = `images/backgrounds/${eventId}`;
      const imageRef = ref(storage, imagePath);
      const uploadTask = uploadBytesResumable(imageRef, compressedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percentageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(percentageProgress);
        },
        (error) => {
          console.error("Upload failed:", error);
          setIsUploading(false);
          setUploadError("Upload failed, please try again.");
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(imageRef);
            await addEventImage(downloadURL, eventId);
            onEventImageAdded(downloadURL);
          } catch (e) {
            console.error(e);
            setUploadError("Upload failed, please try again.");
          } finally {
            setIsUploading(false);
            setUploadError(null);
          }
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId]
  );

  return {
    uploadProgress,
    isUploading,
    uploadError,
    handleUpload,
    isCompressingFile,
  };
};
