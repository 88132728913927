import { useContext, useState } from "react";
import { TextInputField, Button } from ".";
import { Theme } from "../Theme";
import { Formik, Field, FieldProps, Form } from "formik";
import { TableContext } from "../Context/TableContext";
import { selectShouldShowDeleteConfirmation, selectTableNumbers, selectTableOpenInModal } from "../Selectors";
import { AiFillDelete } from "react-icons/ai";
import { useUpdateTable } from "../CustomHooks/useUpdateTable";
import { AlignColumnItemsCenter, FixedSizeContainer, Flex1Container, StyledText } from "../Styles";
import { useDeleteTable } from "../CustomHooks/useDeleteTable";

interface IEditTableFormProps {
  closeModal: () => void;
}
const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white, fontSize: "16px" },
  inputFieldAndErrorDisplayContainerStyle: { width: "100%", marginTop: "15px" },
};
const buttonStyles = {
  width: "100%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
  marginTop: "20px",
};
const iconStyle = { width: 20, height: 20, cursor: "pointer", color: Theme.colors.blackGrey, marginLeft: 4 };
const confirmDeleteTableMessage =
  "Deleting the table won't delete the guests assigned to the current table. The table will be deleted, but the guests will simply be moved to the unassigned list. Do you want to proceed?";
export const EditTableForm = ({ closeModal }: IEditTableFormProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { state } = useContext(TableContext);
  const tableNumbers = selectTableNumbers(state);
  const { deleteTable } = useDeleteTable();
  const { updateTable } = useUpdateTable();
  const tableOpenInModal = selectTableOpenInModal(state);
  const { tableNumber, tableName, capacity, tableId } = tableOpenInModal || {};
  const showSaveTableForm = !showDeleteConfirmation;
  const shouldShowDeleteConfirmation = selectShouldShowDeleteConfirmation(state);
  const showPendingChangesMustBeSaved = state.guestsNeedSaving;
  const saveChangesBeforeEdit = "Pending changes must be saved before editing table details.";

  if (showPendingChangesMustBeSaved) {
    return (
      <AlignColumnItemsCenter>
        <StyledText textStyle="regular" textAlign="justify" lineHeight="23px">
          {saveChangesBeforeEdit}
        </StyledText>
        <Button
          text="Back"
          onPress={closeModal}
          buttonStyles={{
            ...buttonStyles,
            alignSelf: "centet",
            width: "250px",
          }}
        />
      </AlignColumnItemsCenter>
    );
  }

  return (
    <Formik
      initialValues={{
        tableNumber,
        tableName,
        capacity,
      }}
      onSubmit={async (values) => {
        const { tableNumber, tableName, capacity } = values;
        await updateTable({ tableNumber, tableName, capacity, tableId });
        closeModal();
      }}
      validate={(values) => {
        let errors: any = {};
        const { tableNumber, capacity } = values;
        if (!tableNumber) {
          errors.tableNumber = "Required";
        } else if (isNaN(Number(tableNumber))) {
          errors.capacity = "Table number must be a number";
        } else if (tableOpenInModal.tableNumber !== tableNumber && tableNumbers.includes(tableNumber)) {
          errors.tableNumber = "Table number already exists";
        }
        if (!capacity) {
          errors.capacity = "Required";
        } else if (isNaN(Number(capacity))) {
          errors.capacity = "Capacity must be a number";
        }
        return errors;
      }}
    >
      {({ submitForm }) => {
        return (
          <FixedSizeContainer width="250px" height="380px">
            {showSaveTableForm && (
              <>
                <Flex1Container>
                  <Form>
                    <Field name="tableNumber">
                      {({ field, meta }: FieldProps) => {
                        return (
                          <TextInputField
                            label="Table Number"
                            overrideStyles={textInputStyle}
                            {...field}
                            {...meta}
                            placeholder="Enter table number"
                            type="number"
                          />
                        );
                      }}
                    </Field>
                    <Field name="capacity">
                      {({ field, meta }: FieldProps) => {
                        return (
                          <TextInputField
                            label="Table Capacity"
                            overrideStyles={textInputStyle}
                            placeholder="Enter table capacity"
                            type="number"
                            {...field}
                            {...meta}
                          />
                        );
                      }}
                    </Field>
                    <Field name="tableName">
                      {({ field, meta }: FieldProps) => {
                        return (
                          <TextInputField label="Table Name" overrideStyles={textInputStyle} placeholder="Enter table name" {...field} {...meta} />
                        );
                      }}
                    </Field>
                  </Form>
                </Flex1Container>
                <Button type="submit" text="Save" buttonStyles={buttonStyles} isLoading={state.updatingTable} onPress={submitForm} />
                <Button
                  text="Delete"
                  isLoading={state.deletingTable}
                  buttonStyles={{ ...buttonStyles, backgroundColor: "rgb(228,48,63)" }}
                  onPress={async () => {
                    if (shouldShowDeleteConfirmation) {
                      setShowDeleteConfirmation(true);
                      return;
                    }
                    await deleteTable();
                    closeModal();
                  }}
                  Icon={() => {
                    return <AiFillDelete style={iconStyle} />;
                  }}
                />
              </>
            )}
            {showDeleteConfirmation && (
              <>
                <Flex1Container>
                  <StyledText textStyle="regular" textAlign="justify" lineHeight="23px">
                    {confirmDeleteTableMessage}
                  </StyledText>
                </Flex1Container>
                <Button
                  text="Yes"
                  buttonStyles={{ ...buttonStyles, backgroundColor: "rgb(228,48,63)" }}
                  isLoading={state.deletingTable}
                  onPress={async () => {
                    await deleteTable();
                    closeModal();
                  }}
                  Icon={() => {
                    return <AiFillDelete style={iconStyle} />;
                  }}
                />
                <Button
                  text="Back"
                  buttonStyles={buttonStyles}
                  onPress={() => {
                    setShowDeleteConfirmation(false);
                  }}
                />
              </>
            )}
          </FixedSizeContainer>
        );
      }}
    </Formik>
  );
};
