import styled from "styled-components";
import { TextStyles } from "../Theme";

export const StyledRadioOuterCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.black}`};
`;

export const StyledRadioInnerCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const StyledRadioButtonAndLabelContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "0px"};
`;

export const StyledLabel = styled.label`
  ${TextStyles.regular}
`;

export const StyledLabelAndDescriptonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const StyledRadioButtonsDescription = styled.div`
  ${TextStyles.regular}
  color: ${({ theme }) => theme.colors.mediumGrey};
  margin-top: 7px;
`;
