import { useCallback, useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { ModalContext } from "../Context/ModalContext";

export const useUploadModalToggled = () => {
  const { onUploadModalToggled, onShowPaywall } = useContext(ModalContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  return {
    onUploadModalToggled: useCallback(() => {
      if (!user?.hasPaid) {
        onShowPaywall();
      } else {
        onUploadModalToggled();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  };
};
