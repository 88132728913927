import { ReactNode } from "react";
import { StyledCenteredContainer, StyledHeading } from "../../Styles";
import { isDesktop } from "react-device-detect";
import { FaDesktop } from "react-icons/fa";
import { Theme } from "../../Theme";

interface DesktopOnlyComponentProps {
  children: ReactNode;
}

export const DesktopOnlyComponent: React.FC<DesktopOnlyComponentProps> = ({ children }) => {
  if (!isDesktop) {
    return (
      <StyledCenteredContainer padding="30px" flexDirection="column">
        <FaDesktop size={100} style={{ marginBottom: "30px" }} color={Theme.colors.darkBlue} />
        <StyledHeading color={Theme.colors.mediumBlue}>
          <span style={{ color: Theme.colors.darkBlue, fontWeight: "bold" }}> This app is designed for desktop computers.</span>{" "}
        </StyledHeading>
        <br />
        <StyledHeading color={Theme.colors.blackGrey}>Sign in on a desktop browser to continue.</StyledHeading>
      </StyledCenteredContainer>
    );
  } else {
    return <>{children}</>;
  }
};
