import React from "react";

interface IuseSearchInputProps {
  onFinishedSearch?: (searchText: string) => void;
}

export const useSearchInput = ({ onFinishedSearch }: IuseSearchInputProps): [(e: React.ChangeEvent<HTMLInputElement>) => void] => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (onFinishedSearch) {
      onFinishedSearch(value);
    }
  };
  return [onChange];
};
