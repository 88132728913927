import { IoverrideButtonStyles, StyledButton, ToggleButtonContainer } from "../../Styles";
import { Theme } from "../../Theme";
import { IToggleButtonState } from "./SeatingPlanPreview";

const sharedToggleButtonStyles = {
  width: "50%",
  fontSize: "16px",
  border: `1px solid ${Theme.colors.darkBlue}`,
};

let leftToggleButtonStyle: IoverrideButtonStyles = {
  borderTopLeftRadius: "10px",
  borderBottomleftRadius: "10px",
  ...sharedToggleButtonStyles,
};

let rightToggleButtonStyle: IoverrideButtonStyles = {
  borderTopRightRadius: "10px",
  borderBottomRightRadius: "10px",
  ...sharedToggleButtonStyles,
};

const getButtonStyle = (
  isAZSelected: boolean,
  isTableNumberSelected: boolean
): { rightStyle: IoverrideButtonStyles; leftStyle: IoverrideButtonStyles } => {
  const leftStyle: IoverrideButtonStyles = {
    ...leftToggleButtonStyle,
    backgroundColor: isAZSelected ? Theme.colors.darkBlue : Theme.colors.offWhite,
    color: isAZSelected ? Theme.colors.offWhite : Theme.colors.darkBlue,
  };
  const rightStyle: IoverrideButtonStyles = {
    ...rightToggleButtonStyle,
    backgroundColor: isTableNumberSelected ? Theme.colors.darkBlue : Theme.colors.offWhite,
    color: isTableNumberSelected ? Theme.colors.offWhite : Theme.colors.darkBlue,
  };
  return { leftStyle, rightStyle };
};

interface IProps {
  toggleButtonState: IToggleButtonState;
  handleTableNumberToggled(): void;
  handleAZToggled(): void;
}

export const AlphabetTableNumberToggle = ({ toggleButtonState, handleTableNumberToggled, handleAZToggled }: IProps) => {
  const { isTableNumberSelected, isAZSelected } = toggleButtonState;
  const { leftStyle, rightStyle } = getButtonStyle(isAZSelected, isTableNumberSelected);

  return (
    <ToggleButtonContainer>
      <StyledButton overrideStyles={leftStyle} onClick={handleAZToggled}>
        A-Z
      </StyledButton>
      <StyledButton overrideStyles={rightStyle} onClick={handleTableNumberToggled}>
        Table Number
      </StyledButton>
    </ToggleButtonContainer>
  );
};
