import styled from "styled-components";
import { TextStyles } from "../Theme";

export interface ModalContentAndHeaderContainerStyles {
  width?: string;
}

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const StyledModalContentAndHeaderContainer = styled.div<ModalContentAndHeaderContainerStyles>`
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 300px;
  width: ${({ width }) => width};
  border-radius: 20px;
`;
export const StyledModalContentContainer = styled.div`
  padding: 15px 45px;
`;

export const StyledModalHeader = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
  padding: 15px 45px;
  color: ${({ theme }) => theme.colors.blackGrey};
`;

export const StyledModalTitle = styled.div`
  ${TextStyles.regular}
`;
