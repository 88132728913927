import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { StyledProgressCircleContainer, StyledProgressCircleTextContainer } from "../Styles";
import { Theme } from "../Theme";

interface IProgressCircleProps {
  capacity: number;
  numberOfGuestsAtTable: number;
}

export const ProgressCircle = ({ capacity, numberOfGuestsAtTable }: IProgressCircleProps) => {
  const percentage = (numberOfGuestsAtTable / capacity) * 100;
  return (
    <>
      <StyledProgressCircleContainer>
        <CircularProgressbar
          value={percentage}
          strokeWidth={12}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathTransitionDuration: 0.5,
            pathColor: percentage > 100 ? Theme.colors.red : Theme.colors.darkBlue,
            trailColor: Theme.colors.mediumGrey,
          })}
        ></CircularProgressbar>
        <StyledProgressCircleTextContainer>{`${numberOfGuestsAtTable}/${capacity}`}</StyledProgressCircleTextContainer>
      </StyledProgressCircleContainer>
    </>
  );
};
