import { useUploadBackgorundImage } from "../../CustomHooks/useUploadBackgorundImage";
import { useContext, useRef } from "react";
import { Theme } from "../../Theme";
import { Button } from "../Ui";
import { AiFillDelete, AiOutlineCloudUpload } from "react-icons/ai";
import { StyledImageInput } from "../../Styles/EventDetails.styled";
import { CircularProgress } from "../Ui/CircularProgress";
import { StyledSpinner, StyledText } from "../../Styles";
import { Info } from "../Ui/Info";
import { EventContext } from "../../Context/EventContext";
import { useRemoveEventImage } from "../../CustomHooks/useRemoveEventImage";
import { IoIosCloudDone } from "react-icons/io";
import { ModalContext } from "../../Context/ModalContext";

const infoIconStyle = { width: 30, height: 30, cursor: "pointer", color: Theme.colors.darkBlue, marginRight: 10 };

const buttonStyles = {
  width: "70%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
  marginTop: "20px",
  marginLeft: "auto",
  marginRight: "auto",
};

export const BackgroundImageUpload = () => {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const { state } = useContext(EventContext);
  const { onCloseAllModals } = useContext(ModalContext);
  const { uploadProgress, handleUpload, uploadError, isUploading, isCompressingFile } = useUploadBackgorundImage();
  const { removeImageFromEvent, isRemovingImage, hasJustRemovedImage } = useRemoveEventImage();
  const uploadCompleted = uploadProgress === 100;
  const showProgress = isUploading || uploadCompleted || isCompressingFile;
  const infoText = "This image will be used as the background image of your digital seating plan.";
  const iconStyle = { width: 20, height: 20, margin: "0px 10px 0px 0px", marginLeft: 4 };
  const showRemoveImageBtn = !!state.event?.imageUrl;
  const handleFileChange = () => {
    const file = imageInputRef.current?.files?.[0];
    if (file) {
      handleUpload(file);
    }
  };
  const handleUploadClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  if (hasJustRemovedImage) {
    return (
      <>
        <div style={{ margin: "auto", width: "max-content" }}>
          <IoIosCloudDone style={{ width: 40, height: 40, color: Theme.colors.darkBlue }} />
        </div>
        <div style={{ margin: "auto", width: "max-content" }}>
          <StyledText textStyle="regular">Image removed successfully</StyledText>
        </div>
        <Button buttonStyles={buttonStyles} text={"Done"} onPress={onCloseAllModals} />
      </>
    );
  }

  if (isRemovingImage) {
    return (
      <>
        <StyledSpinner size={"40px"} color={Theme.colors.darkBlue} margin="5px auto 40px auto" />
        <Button buttonStyles={buttonStyles} text={"Done"} disabled={true} />
      </>
    );
  }

  if (uploadError) {
    return (
      <StyledText textStyle="regular" color={Theme.colors.red}>
        There was an error processing your image, please try again.
      </StyledText>
    );
  }

  if (showProgress) {
    return (
      <div>
        <CircularProgress
          size={120}
          strokeWidth={10}
          trackColor={Theme.colors.grey}
          progressColor={Theme.colors.darkBlue}
          progress={uploadProgress}
          containerStyle={{ margin: "auto" }}
        />
        <Button buttonStyles={buttonStyles} text={"Done"} disabled={!uploadCompleted} onPress={onCloseAllModals} />
      </div>
    );
  }

  return (
    <>
      <Info text={infoText} iconStyle={infoIconStyle} textStyle="regular" containerStyle={{ margin: "20px 0px" }} />
      <StyledImageInput type="file" ref={imageInputRef} accept="image/*" onChange={handleFileChange} />
      <Button
        onPress={handleUploadClick}
        text={"Upload Image"}
        Icon={() => {
          return <AiOutlineCloudUpload style={iconStyle} />;
        }}
        buttonStyles={buttonStyles}
      />
      {showRemoveImageBtn && (
        <Button
          onPress={removeImageFromEvent}
          text={"Remove image"}
          Icon={() => {
            return <AiFillDelete style={{ ...iconStyle, color: "red" }} />;
          }}
          buttonStyles={{
            ...buttonStyles,
            backgroundColor: Theme.colors.white,
            color: Theme.colors.red,
            border: "1px solid red",
            marginBottom: "20px",
          }}
        />
      )}
    </>
  );
};
