import { createSelector } from "reselect";
import { IModalReducerState } from "../Context/ModalReducer";
import { ModalContentAndHeaderContainerStyles } from "../Styles";
const selectModalState = (state: IModalReducerState) => state;

export const selectModalTitle: (state: IModalReducerState) => string = createSelector(selectModalState, (state) => {
  if (state.isTableModalOpen) {
    return "Add Tables";
  }
  if (state.isGuestModalOpen) {
    return "Add Guests";
  }
  if (state.isUploadModalOpen) {
    return "Upload Guests";
  }
  if (state.showPayWall) {
    return "Premium Features";
  }
  if (state.isEditTableModalOpen) {
    return "Edit Table";
  }
  if (state.isEventNameModalOpen) {
    return "Edit Event Name";
  }
  if (state.isImageModalOpen) {
    return "Upload  image";
  }
  if (state.isEditGuestModalOpen) return "Edit Guest";
  return "";
});

export const selectModalStyle: (state: IModalReducerState) => ModalContentAndHeaderContainerStyles = createSelector(selectModalState, (state) => {
  if (state.isTableModalOpen) {
    return { width: "780px" };
  }
  return {};
});

export const selectIsModalOpen: (state: IModalReducerState) => boolean = createSelector(selectModalState, (state) => {
  return Object.values(state).findIndex((val) => val) > -1;
});
