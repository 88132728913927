import styled from "styled-components";
import { TextStyles } from "../Theme";

export const StyledSidePannelContainer = styled.div`
  width: 273px;
  position: sticky;
  top: 80px;
  display: flex;
  background-color: ${({ theme }) => {
    return theme.colors.lightBlue;
  }};
  color: ${({ theme }) => {
    return theme.colors.blackGrey;
  }};
  flex-direction: column;
  z-index: 10;
`;

export const StyledSidePannelHeading = styled.div`
  ${TextStyles.regular}
  margin-top: 40px;
  margin-left: 30px;
  margin-bottom: 12px;
  color: black;
  font-weight: bold;
`;

export const StyledSidePannelSubHeading = styled.div`
  ${TextStyles.regular}
  max-width: 230px;
  margin-left: 30px;
  font-weight: 400;
`;

export const StyledSidePannelGuestListContainer = styled.div`
  flex: 1;
  overflow: scroll;
`;

export const StyledFixedContentContainer = styled.div`
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
`;
