import { ProgressContainer, ProgressStep, ProgressStepAndLabelContainer, ProgressLabel, ProgresLine } from "../../Styles";

interface IProps {
  numberOfSteps: number;
  numberOfCompleteSteps: number;
  size: number;
  labels: string[];
}

export const FormProgress = ({ numberOfSteps, labels, size, numberOfCompleteSteps }: IProps) => {
  const completed = Math.max(0, numberOfCompleteSteps);
  const ProgressLineWidth = Math.min(completed / (numberOfSteps - 1), 1) * 100 + "%";
  return (
    <ProgressContainer size={size}>
      <ProgresLine width={ProgressLineWidth} />

      {Array.from({ length: numberOfSteps }).map((_, i) => {
        const isActive = i === numberOfCompleteSteps;
        const isComplete = numberOfCompleteSteps > i;
        const marginLeft = `calc(${(i / (numberOfSteps - 1)) * 100}% - ${i > 0 ? size : 0}px)`;
        return (
          <ProgressStepAndLabelContainer marginLeft={marginLeft} key={i}>
            <ProgressStep isActive={isActive} size={size} isComplete={isComplete} />
            <ProgressLabel>{labels[i]}</ProgressLabel>
          </ProgressStepAndLabelContainer>
        );
      })}
    </ProgressContainer>
  );
};
