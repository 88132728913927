import styled from "styled-components";
import { TextStyles } from "../Theme";
import { ISingleGuestDisplayProps } from "../Components";

export const StyledSingleGuestDisplayContainer = styled.div<Pick<ISingleGuestDisplayProps, "hasBorderBottom" | "isDragging">>`
  ${TextStyles.regular}
  display: flex;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.grey};
  border-style: solid;
  border-bottom-width: ${(props) => (props.hasBorderBottom ? "1px" : "0px")};
  color: ${({ theme }) => theme.colors.blackGrey};
  padding: 15px;
  cursor: pointer;
  &::after {
    content: "";
    background: ${({ theme }) => theme.colors.grey};
    position: absolute;
    top: 0;
    left: 5%;
    width: 90%;
    height: ${({ isDragging }) => (isDragging ? "0px" : "0.5px")};
  }
`;

export const StyledColoredLetter = styled.pre`
  background-color: yellow;
  font-weight: bold;
  font-size: 16px;
`;

export const StyledInitialsCircle = styled.div`
  ${TextStyles.regular}
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const StyledNameContainer = styled.div``;
