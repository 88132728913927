import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "testKey",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

export default ErrorBoundary;
