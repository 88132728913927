/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
import { IAlphabeticalList } from "../../Selectors";
import {
  IBorderStyle,
  StyledAlphabeticalLetterContainer,
  StyledLetterCircle,
  StyledNameAndTableNumberContainer,
  TableAndNameText,
} from "../../Styles";

interface IProps {
  letterAndList: IAlphabeticalList;
  textStyle?: string;
  fontColor?: string;
  borderStyle?: IBorderStyle;
  fontFamily?: string;
  backgroundColor?: string;
  fontSize?: string;
  margin?: string;
  hasLinkId?: boolean;
  nameWidth?: string;
}

export const AlphabeticalLetter = ({
  letterAndList,
  textStyle,
  fontColor,
  borderStyle = {},
  fontFamily,
  fontSize,
  backgroundColor,
  margin,
  hasLinkId,
  nameWidth,
}: IProps) => {
  return (
    <StyledAlphabeticalLetterContainer {...borderStyle}>
      {hasLinkId && <a style={{ visibility: "hidden", padding: 10 }} aria-hidden={true} id={letterAndList.letter}></a>}
      <StyledLetterCircle fontFamily={fontFamily} fontSize={fontSize} backgroundColor={backgroundColor} margin={margin}>
        {letterAndList.letter}
      </StyledLetterCircle>
      {letterAndList.list.map((seatInfo, index) => {
        return (
          <StyledNameAndTableNumberContainer key={index}>
            <TableAndNameText overrideStyles={{ marginRight: "30px", width: nameWidth ?? "150px", textStyle, fontColor, fontFamily }}>
              {seatInfo.fullname}
            </TableAndNameText>
            <TableAndNameText overrideStyles={{ width: "70px", textStyle, fontColor, fontFamily }}>{seatInfo.tableNumberAsString}</TableAndNameText>
          </StyledNameAndTableNumberContainer>
        );
      })}
    </StyledAlphabeticalLetterContainer>
  );
};
