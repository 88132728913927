import styled, { css, keyframes } from "styled-components";
import { TextStyles } from "../Theme";

const jump = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;
export interface IoverrideButtonStyles {
  width?: string;
  height?: string;
  backgroundColor?: string;
  color?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  borderRadius?: string;
  alignSelf?: string;
  flexShrink?: number;
  position?: string;
  bottom?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomleftRadius?: string;
  textStyle?: any;
  border?: string;
  fontSize?: string;
  right?: string;
  padding?: string;
}

export interface IStyledButtonProps {
  overrideStyles?: IoverrideButtonStyles;
  jumping?: boolean;
}

export const StyledButton = styled.button<IStyledButtonProps>`
  ${({ overrideStyles }) => (overrideStyles && overrideStyles.textStyle ? TextStyles[overrideStyles.textStyle] : TextStyles.regular)}
  color: ${({ theme, overrideStyles }) => (overrideStyles ? overrideStyles.color : theme.colors.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: ${({ overrideStyles }) => overrideStyles && overrideStyles.alignSelf};
  background-color: ${({ theme, overrideStyles }) =>
    overrideStyles && overrideStyles.backgroundColor ? overrideStyles.backgroundColor : theme.colors.darkBlue};
  border-top-right-radius: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderTopRightRadius};
  border-top-left-radius: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderTopLeftRadius};
  border-bottom-left-radius: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderBottomleftRadius};
  border-bottom-right-radius: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderBottomRightRadius};
  border-radius: ${({ overrideStyles }) => overrideStyles && overrideStyles.borderRadius};
  width: ${({ overrideStyles }) => overrideStyles && overrideStyles.width};
  height: ${({ overrideStyles }) => overrideStyles && overrideStyles.height};
  border: ${({ overrideStyles }) => (overrideStyles && overrideStyles.border ? overrideStyles.border : "none")};
  margin-left: ${({ overrideStyles }) => overrideStyles && overrideStyles.marginLeft};
  margin-right: ${({ overrideStyles }) => overrideStyles && overrideStyles.marginRight};
  margin-top: ${({ overrideStyles }) => overrideStyles && overrideStyles && overrideStyles.marginTop};
  bottom: ${({ overrideStyles }) => overrideStyles && overrideStyles.bottom};
  position: ${({ overrideStyles }) => overrideStyles && overrideStyles.position};
  margin-bottom: ${({ overrideStyles }) => overrideStyles && overrideStyles.marginBottom};
  font-size: ${({ overrideStyles }) => overrideStyles && overrideStyles.fontSize};
  right: ${({ overrideStyles }) => overrideStyles && overrideStyles.right};
  padding: ${({ overrideStyles }) => overrideStyles && overrideStyles.padding};
  cursor: pointer;
  &:active {
    opacity: 0.5;
    flex-shrink: ${({ overrideStyles }) => overrideStyles && overrideStyles.flexShrink};
  }
  &:disabled {
    opacity: 0.4;
  }
  ${({ jumping }) =>
    jumping &&
    css`
      animation: ${jump} 1s ease infinite;
    `}
  &:hover {
    animation-play-state: paused;
  }
`;
