import React, { useCallback, useRef, useLayoutEffect } from "react";
import { StyledTable } from "../Styles";
import { TableHeader, DroppableContainer } from ".";
import { TGuest } from "../Context/tableReducer";
import { isEqual } from "lodash";
import { logger } from "../Logger";

export interface ITableProps {
  capacity: number;
  tableName: string;
  tableNumber: number;
  droppableId: string;
  guests: TGuest[];
  tableRowToScrollTo?: number;
  scrollIntoView: boolean;
  showDropZone: boolean;
}

export const Table = React.memo(
  ({ capacity, tableName, droppableId, guests, tableNumber, tableRowToScrollTo, scrollIntoView, showDropZone }: ITableProps) => {
    logger.log(`rendering table ${tableNumber}`);
    const tableRef = useRef<any>();
    useLayoutEffect(() => {
      if (tableRef && tableRef.current && scrollIntoView) {
        tableRef.current.scrollIntoView({ block: "end" });
      }
    }, [scrollIntoView]);
    const getHeight = useCallback(() => {
      if (showDropZone) {
        return 63;
      }
      return 220;
    }, [showDropZone]);

    return (
      <StyledTable ref={tableRef}>
        <TableHeader
          capacity={capacity}
          tableName={tableName}
          numberOfGuestsAtTable={guests.length}
          showDropZone={showDropZone}
          tableId={droppableId}
          tableNumber={tableNumber}
        />
        <DroppableContainer
          droppableId={droppableId}
          getHeight={getHeight}
          width={273}
          guests={guests}
          showDropZone={showDropZone}
          tableRowToScrollTo={scrollIntoView ? tableRowToScrollTo : undefined}
        ></DroppableContainer>
      </StyledTable>
    );
  },
  isEqual
);
