import styled from "styled-components";

export const StyledBoard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
`;

export const StyledBoardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
