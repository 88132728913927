import { createContext, useReducer } from "react";
import {
  CLOSE_ALL_MODALS,
  GUEST_MODAL_TOGGLED,
  IModalReducerState,
  modalReducer,
  ADD_TABLE_MODAL_TOGGLED,
  UPLOAD_MODAL_TOGGLED,
  EDIT_TABLE_MODAL_TOGGLED,
  EDIT_GUEST_MODAL_TOGGLED,
  SHOW_PAYWALL,
  EDIT_EVENT_NAME_TOGGLED,
  UPlOAD_IMAGE_MODAL_TOGGLED,
} from "./ModalReducer";

interface IModalContext {
  state: IModalReducerState;
  onTableModalToggled: () => void;
  onGuestModalToggled: () => void;
  onUploadModalToggled: () => void;
  onEditTableModalOpened(): void;
  onEditGuestModalOpened(): void;
  onEditEventNameModalOpened(): void;
  onCloseAllModals: () => void;
  onUploadImageModalOpened(): void;
  onShowPaywall(): void;
}
export const ModalContext = createContext<IModalContext>({
  state: {
    isTableModalOpen: false,
    isGuestModalOpen: false,
    isUploadModalOpen: false,
    isEditTableModalOpen: false,
    isEditGuestModalOpen: false,
    isEventNameModalOpen: false,
    isImageModalOpen: false,
    showPayWall: false,
  },
  onTableModalToggled: () => {},
  onGuestModalToggled: () => {},
  onUploadModalToggled: () => {},
  onEditTableModalOpened() {},
  onEditGuestModalOpened() {},
  onEditEventNameModalOpened() {},
  onCloseAllModals: () => {},
  onShowPaywall() {},
  onUploadImageModalOpened() {},
});

const initialState = {
  isTableModalOpen: false,
  isGuestModalOpen: false,
  isUploadModalOpen: false,
  isEditTableModalOpen: false,
  isEditGuestModalOpen: false,
  isEventNameModalOpen: false,
  isImageModalOpen: false,
  showPayWall: false,
};

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const onTableModalToggled = () => {
    dispatch({ type: ADD_TABLE_MODAL_TOGGLED });
  };

  const onGuestModalToggled = () => {
    dispatch({ type: GUEST_MODAL_TOGGLED });
  };

  const onUploadModalToggled = () => {
    dispatch({ type: UPLOAD_MODAL_TOGGLED });
  };

  const onCloseAllModals = () => {
    dispatch({ type: CLOSE_ALL_MODALS });
  };
  const onEditTableModalOpened = () => {
    dispatch({ type: EDIT_TABLE_MODAL_TOGGLED });
  };

  const onEditGuestModalOpened = () => {
    dispatch({ type: EDIT_GUEST_MODAL_TOGGLED });
  };

  const onEditEventNameModalOpened = () => {
    dispatch({ type: EDIT_EVENT_NAME_TOGGLED });
  };

  const onUploadImageModalOpened = () => {
    dispatch({ type: UPlOAD_IMAGE_MODAL_TOGGLED });
  };

  const onShowPaywall = () => {
    dispatch({ type: SHOW_PAYWALL });
  };

  return (
    <ModalContext.Provider
      value={{
        state,
        onTableModalToggled,
        onGuestModalToggled,
        onUploadModalToggled,
        onCloseAllModals,
        onEditTableModalOpened,
        onEditGuestModalOpened,
        onEditEventNameModalOpened,
        onShowPaywall,
        onUploadImageModalOpened,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
