import { ChangeEvent, useEffect } from "react";
import { StyledFormStepExplanation, StyledFormatText } from "../../Styles";
import { GuestFormat } from "../../Context/tableReducer";

interface IProps {
  format: GuestFormat | undefined;
  onResetFileSelection(): void;
  onFileSelected(e: ChangeEvent<HTMLInputElement>): void;
}

export const UploadTemplate = ({ format, onFileSelected, onResetFileSelection }: IProps) => {
  const instructionText =
    "If you have not created a csv/excel file with the necessary data, please do so. Please note that duplicate guests wont be uploaded. Please ensure that the csv/excel file contains the following column headings, as depicted in the image:";
  useEffect(() => {
    onResetFileSelection();
  }, [onResetFileSelection]);
  const fileName = format === GuestFormat.Format1 ? "format1" : "format2";
  return (
    <>
      <StyledFormStepExplanation>
        {instructionText}
        {format?.split(",").map((text, index) => {
          const hasComma = index !== format.split(",").length - 1;
          return <StyledFormatText>{text + (hasComma ? ", " : "")}</StyledFormatText>;
        })}
        .
      </StyledFormStepExplanation>
      <img src={require(`../../assets/${fileName}.png`)} width={250} style={{ marginBottom: 30 }} alt="img of excel file with correct headings" />
      <input type="file" onChange={onFileSelected} accept=".csv, .xlsx, .xls" />
    </>
  );
};
