import { useContext } from "react";
import { TextInputField, Button } from "..";
import { AuthContext } from "../../Context/AuthContext";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  StyledAuthContainer,
  StyledAuthForm,
  StyledWelcomeText,
  StyledRegularAuthText,
  StyledHaveAccountText,
  StyledAuthFormContainer,
  StyledAuthLink,
  AuthLinkContainer,
  ButtonAndFormErrorContainer,
  StyledErrorText,
} from "../../Styles";
import { Theme } from "../../Theme";
import { Formik, Field, FieldProps } from "formik";
import * as Yup from "yup";

const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white },
  inputFieldAndErrorDisplayContainerStyle: { width: "100%", marginTop: "15px" },
};

const buttonStyles = {
  width: "100%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
};
const signupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  password: Yup.string().min(6, "Passowrd must be at least 6 characters").required("Required"),
});

export const Signup = () => {
  const {
    signup,
    navigateFromAuthForm,
    state: { signupLoading, signupError },
  } = useContext(AuthContext);

  return (
    <StyledAuthContainer>
      <StyledAuthFormContainer>
        <Logo width={"80"} height={"80"} />
        <StyledWelcomeText>Hey, Welcome!</StyledWelcomeText>
        <StyledRegularAuthText>Create an account to get started</StyledRegularAuthText>
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstName: "",
            lastName: "",
          }}
          onSubmit={(values) => {
            signup(values);
          }}
          validationSchema={signupSchema}
        >
          {({ errors, dirty }) => {
            return (
              <StyledAuthForm>
                <Field name="firstName">
                  {({ field, meta }: FieldProps) => {
                    return <TextInputField label="First name" overrideStyles={textInputStyle} placeholder="Enter first name" {...field} {...meta} />;
                  }}
                </Field>
                <Field name="lastName">
                  {({ field, meta }: FieldProps) => {
                    return <TextInputField label="Last name" overrideStyles={textInputStyle} placeholder="Enter last name" {...field} {...meta} />;
                  }}
                </Field>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    return <TextInputField label="Email" overrideStyles={textInputStyle} placeholder="rob@example.com" {...field} {...meta} />;
                  }}
                </Field>
                <Field name="password">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <TextInputField
                        label="Password"
                        overrideStyles={textInputStyle}
                        autoComplete="new-password"
                        placeholder="Create a password"
                        type="password"
                        {...field}
                        {...meta}
                      />
                    );
                  }}
                </Field>
                <ButtonAndFormErrorContainer>
                  {signupError && <StyledErrorText>{signupError}</StyledErrorText>}
                  <Button isLoading={signupLoading} type="submit" text="Sign up" buttonStyles={buttonStyles} />
                </ButtonAndFormErrorContainer>
              </StyledAuthForm>
            );
          }}
        </Formik>
        <AuthLinkContainer>
          <StyledHaveAccountText>Already have an account? </StyledHaveAccountText>
          <StyledAuthLink to="/signin" onClick={navigateFromAuthForm}>
            Sign in
          </StyledAuthLink>
        </AuthLinkContainer>
      </StyledAuthFormContainer>
    </StyledAuthContainer>
  );
};
