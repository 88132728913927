import styled from "styled-components";
import { TextStyles } from "../Theme";

interface ICircleProps {
  size: number;
}

export interface IContainerStyle {
  margin: string;
}

export const StyledSvgContainer = styled.svg`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
`;

export const StyledCircleContainer = styled.div<ICircleProps & IContainerStyle>`
  position: relative;
  width: ${({ size }) => size + "px"};
  height: ${({ size }) => size + "px"};
  margin: ${({ margin }) => margin};
`;

export const StyledCircleLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  ${TextStyles.regular}
`;

// export const StyledCircleTrack = styled.circle<ITrackProps>`
//   cx: ${({ size }) => size / 2 + "px"};
//   cy: ${({ size }) => size / 2 + "px"};
//   r: ${({ radius }) => radius + "px"};
//   fill: transparent;
//   stroke-width: ${({ strokeWidth }) => strokeWidth + "px"};
//   stroke: ${({ strokeColor }) => strokeColor};
// `;

// export const StyledCircleProgress = styled.circle<ITrackProps>`
//   cx: ${({ size }) => size / 2 + "px"};
//   cy: ${({ size }) => size / 2 + "px"};
//   r: ${({ radius }) => radius + "px"};
//   fill: transparent;
//   stroke-width: ${({ strokeWidth }) => strokeWidth + "px"};
//   stroke: ${({ strokeColor }) => strokeColor};
// `;
