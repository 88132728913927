import { TextInputField, Button } from "..";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  StyledAuthContainer,
  StyledAuthForm,
  StyledWelcomeText,
  StyledRegularAuthText,
  StyledHaveAccountText,
  StyledAuthFormContainer,
  StyledAuthLink,
  AuthLinkContainer,
  ButtonAndFormErrorContainer,
  StyledErrorText,
  StyledText,
} from "../../Styles";
import { Theme } from "../../Theme";
import { Formik, Field, FieldProps } from "formik";
import * as Yup from "yup";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../Firebase";
import { useState } from "react";
import { logger } from "../../Logger";

const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white },
  inputFieldAndErrorDisplayContainerStyle: { width: "100%", marginTop: "15px" },
};

const buttonStyles = {
  width: "100%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
};
const signupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export const PasswordReset = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const resetPassword = async (email: string): Promise<void> => {
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (error) {
      logger.error(error);
      setError("There was an error sending the reset password link, please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <StyledAuthContainer>
      <StyledAuthFormContainer>
        <Logo width={"80"} height={"80"} />
        <StyledWelcomeText>Hey, Welcome!</StyledWelcomeText>
        <StyledRegularAuthText>Create an account to get started</StyledRegularAuthText>
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstName: "",
            lastName: "",
          }}
          onSubmit={(values) => {
            resetPassword(values.email);
          }}
          validationSchema={signupSchema}
        >
          {({ errors, dirty }) => {
            return (
              <StyledAuthForm>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    return <TextInputField label="Email" overrideStyles={textInputStyle} placeholder="rob@example.com" {...field} {...meta} />;
                  }}
                </Field>
                <ButtonAndFormErrorContainer>
                  {error && <StyledErrorText>{error}</StyledErrorText>}
                  {success && (
                    <StyledText textStyle="regular" color={Theme.colors.darkBlue} margin="0px 0px 10px 0px">
                      An Email has been sent with a link to reset your password
                    </StyledText>
                  )}
                  <Button isLoading={isLoading} type="submit" text="Reset password" buttonStyles={buttonStyles} />
                </ButtonAndFormErrorContainer>
              </StyledAuthForm>
            );
          }}
        </Formik>
        <AuthLinkContainer>
          <StyledHaveAccountText></StyledHaveAccountText>
          <StyledAuthLink to="/signin">Sign in</StyledAuthLink>
        </AuthLinkContainer>
      </StyledAuthFormContainer>
    </StyledAuthContainer>
  );
};
