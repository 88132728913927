import React, { useContext } from "react";
import {
  StyledTableHeading,
  StyledCircleAndCaretContainer,
  StyledTableHeadingContaier,
  StyledTableHeadingAndEditIConContainer,
  StyledTableName,
  StyledTableNameAndNumberContainer,
} from "../Styles";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { FaPen } from "react-icons/fa";
import { Theme } from "../Theme";
import { ProgressCircle } from "./ProgressCircle";
import { TableContext } from "../Context/TableContext";
import { ModalContext } from "../Context/ModalContext";

export interface ITableHeaderProps {
  capacity: number;
  tableName: string;
  tableNumber: number;
  numberOfGuestsAtTable: number;
  showDropZone: boolean;
  tableId: string;
}

export const TableHeader = React.memo(({ showDropZone, tableName, capacity, numberOfGuestsAtTable, tableNumber, tableId }: ITableHeaderProps) => {
  const { onTableCollapsed, onTableModalOpened } = useContext(TableContext);
  const { onEditTableModalOpened } = useContext(ModalContext);
  const onCaretClicked = () => {
    onTableCollapsed({ tableId });
  };

  return (
    <StyledTableHeadingContaier showDropZone={showDropZone}>
      <StyledTableNameAndNumberContainer>
        <StyledTableHeadingAndEditIConContainer
          onClick={() => {
            onTableModalOpened(tableId);
            onEditTableModalOpened();
          }}
        >
          <StyledTableHeading>Table {tableNumber}</StyledTableHeading>
          <FaPen style={{ color: Theme.colors.darkBlue, width: 12, height: 12, marginLeft: 13 }} />
        </StyledTableHeadingAndEditIConContainer>
        {tableName && <StyledTableName>{tableName}</StyledTableName>}
      </StyledTableNameAndNumberContainer>
      <StyledCircleAndCaretContainer>
        <ProgressCircle capacity={capacity} numberOfGuestsAtTable={numberOfGuestsAtTable} />
        {!!numberOfGuestsAtTable && (
          <>
            {showDropZone && (
              <IoIosArrowDown style={{ color: Theme.colors.darkBlue, width: 20, height: 20, marginLeft: 12, zIndex: 100 }} onClick={onCaretClicked} />
            )}
            {!showDropZone && (
              <IoIosArrowUp style={{ color: Theme.colors.darkBlue, width: 20, height: 20, marginLeft: 12 }} onClick={onCaretClicked} />
            )}
          </>
        )}
      </StyledCircleAndCaretContainer>
    </StyledTableHeadingContaier>
  );
});
