import styled from "styled-components";
import { TextStyles } from "../Theme";
import { ITableHeaderProps } from "../Components";
import { FixedSizeList } from "react-window";

export const StyledTableHeadingContaier = styled.div<Partial<ITableHeaderProps>>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 10px 13px 10px 23px;
  position: ${({ showDropZone }) => (showDropZone ? "absolute" : "relative")};
`;

export const StyledProgressCircleContainer = styled.div`
  height: 43px;
  width: 43px;
  position: relative;
  display: flex;
  ${TextStyles.small12}
`;

export const StyledProgressCircleTextContainer = styled.div`
  color: ${({ theme }) => theme.colors.blackGrey};
  ${TextStyles.small12}
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledTableHeading = styled.div`
  ${TextStyles.regular}
  color: ${({ theme }) => theme.colors.darkBlue};
`;
export const StyledTableHeadingAndEditIConContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  background: unset;
  cursor: pointer;
  padding: 0px;
  &:active {
    opacity: 0.5;
  }
`;

export const StyledCircleAndCaretContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTable = styled.div`
  width: 273px;
  margin: 10px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  position: relative;
  height: max-content;
`;

export const StyledTableName = styled.div`
  ${TextStyles.regular}
  font-size: 14px;
  height: 20px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
`;

export const StyledTableNameAndNumberContainer = styled.div``;

export const StyledFixedSizeList = styled(FixedSizeList)`
  /* transition: height 0.2s ease-in; */
`;
