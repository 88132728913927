import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset}
  html,body,#root{ 
    width: 100%;
    height: 100%;
  }
  #root {
    display: flex ;
}

  body{
    overscroll-behavior:none;
  }
  *{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 250ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
  .bm-burger-button {
  position: fixed;
  width: 16px;
  height: 16px;
  left: 15px;
  top: 15px;
  display: none;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-menu {
  background: white;
  text-align: center;
}


@media (max-width: 767px) {
  .bm-burger-button  {
    display: block;
  }
}
`;

export default GlobalStyle;
