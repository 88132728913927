import React from "react";
import { StyledSearchBarContainer, StyledSearchInput, StyledSearchBarIconContainer } from "../Styles";
import { AiOutlineSearch } from "react-icons/ai";
import { Theme } from "../Theme";

export interface ISearchBarOverrideStyles {
  backgroundColor?: string;
  position?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  alignSelf?: string;
  right?: string;
  borderWidth?: string;
}

export interface ISearchBarProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  overrideStyles: ISearchBarOverrideStyles;
  value: string;
}

export const SearchBar = ({ onChange, overrideStyles, placeholder, value }: ISearchBarProps) => {
  return (
    <StyledSearchBarContainer overrideStyles={overrideStyles}>
      <StyledSearchBarIconContainer>
        <AiOutlineSearch style={{ width: 16, height: 16, color: Theme.colors.mediumGrey }} />
      </StyledSearchBarIconContainer>
      <StyledSearchInput onChange={onChange} overrideStyles={overrideStyles} placeholder={placeholder} value={value} />
    </StyledSearchBarContainer>
  );
};
