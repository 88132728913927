import { IContainerStyle, StyledCircleContainer, StyledCircleLabel, StyledSvgContainer } from "../../Styles/Circle.styled";

interface IProps {
  size: number;
  strokeWidth: number;
  trackColor: string;
  progressColor: string;
  progress: number;
  containerStyle: IContainerStyle;
}
export const CircularProgress: React.FC<IProps> = ({ size, strokeWidth, trackColor, progressColor, progress, containerStyle }) => {
  const center = size / 2;
  const radius = center - strokeWidth;
  const strokeDasharray = 2 * Math.PI * radius;
  const strokeDashoffset = strokeDasharray * ((100 - progress) / 100);
  const formattedProgress = Math.min(Math.ceil(progress), 100);

  return (
    <StyledCircleContainer size={size} {...containerStyle}>
      <StyledSvgContainer>
        <circle r={radius} strokeWidth={strokeWidth} stroke={trackColor} cx={center} cy={center} fill="transparent" />
        <circle
          r={radius}
          strokeWidth={strokeWidth}
          stroke={progressColor}
          cx={center}
          cy={center}
          fill="transparent"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </StyledSvgContainer>
      <StyledCircleLabel>{formattedProgress}%</StyledCircleLabel>
    </StyledCircleContainer>
  );
};
