import { useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";

export const useDeleteGuest = () => {
  const {
    deleteGuest,
    state: { guestPaths },
  } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    deleteGuest: async (guestId: string, tableId = "sidePannel") => {
      const guestHasNotBeenMoved = !guestPaths[guestId];
      const deleteGuestFrom = guestHasNotBeenMoved ? tableId : guestPaths[guestId][0];
      await deleteGuest({ tableIdToDeleteFrom: deleteGuestFrom, guestId, tableId }, eventId);
    },
  };
};
