import { createContext, useReducer } from "react";
import {
  ADD_EVENT_NAME_FAILURE,
  ADD_EVENT_NAME_LOADING,
  ADD_EVENT_NAME_SUCCESS,
  FETCH_ONE_EVENT_FAILURE,
  FETCH_ONE_EVENT_LOADING,
  FETCH_ONE_EVENT_SUCCESS,
  IEventReducerState,
  IMAGE_REMOVE_SUCCESS,
  IMAGE_UPLOAD_SUCCESS,
  eventReducer,
} from "./EventReducer";
import { addEventName as addEventNameInDB, getEvent } from "../Firebase/firestore";
interface IEventContext {
  state: IEventReducerState;
  addEventName(eventName: string, eventId: string): Promise<void>;
  onEventImageAdded(imageUrl: string): void;
  onEventImageRemoved(): void;
  fetchOneEvent(eventId: string): Promise<void>;
}
const initialState = {
  isAddingEvent: false,
  isFetchingEvent: true,
};
export const EventContext = createContext<IEventContext>({
  state: initialState,
  addEventName: () => {
    throw new Error("method not initialized");
  },
  onEventImageAdded: () => {
    throw new Error("method not initialized");
  },
  onEventImageRemoved: () => {
    throw new Error("method not initialized");
  },
  fetchOneEvent: () => {
    throw new Error("method not initialized");
  },
});

export const EventProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(eventReducer, initialState);

  const addEventName = async (eventName: string, eventId: string) => {
    try {
      dispatch({ type: ADD_EVENT_NAME_LOADING });
      await addEventNameInDB(eventName, eventId);
      dispatch({ type: ADD_EVENT_NAME_SUCCESS, payload: { eventName } });
    } catch (e) {
      dispatch({ type: ADD_EVENT_NAME_FAILURE });
    }
  };

  const fetchOneEvent = async (eventId: string) => {
    try {
      console.warn("fetching event");
      dispatch({ type: FETCH_ONE_EVENT_LOADING });
      const event = await getEvent(eventId);
      dispatch({ type: FETCH_ONE_EVENT_SUCCESS, payload: { event } });
    } catch (e) {
      dispatch({ type: FETCH_ONE_EVENT_FAILURE });
    }
  };

  const onEventImageAdded = (imageUrl: string) => {
    dispatch({ type: IMAGE_UPLOAD_SUCCESS, imageUrl });
  };
  const onEventImageRemoved = () => {
    dispatch({ type: IMAGE_REMOVE_SUCCESS });
  };

  return (
    <EventContext.Provider
      value={{
        state,
        addEventName,
        fetchOneEvent,
        onEventImageAdded,
        onEventImageRemoved,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
