import React, { useState, useRef, useEffect } from "react";
import { Button } from "./Button";
import { Theme } from "../../Theme";
import { VscCopy } from "react-icons/vsc";

interface Props {
  textToCopy: string;
}

export const CopyToClipboardButton: React.FC<Props> = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const buttonText = isCopied ? "Copied!" : "Copy";

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <Button
      onPress={handleCopyClick}
      text={buttonText}
      Icon={() => <VscCopy style={{ marginLeft: 4 }} />}
      buttonStyles={{
        padding: "2px",
        color: Theme.colors.white,
        borderRadius: "10px",
        marginLeft: "10px",
        backgroundColor: Theme.colors.darkBlue,
        border: `1px solid ${Theme.colors.darkBlue}`,
      }}
    />
  );
};
