import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { StyledSpinner } from "../../Styles";
import { Theme } from "../../Theme";

interface IProps {
  children: ReactNode;
}

export const RedirectToInAppRoutesIfAuthed = ({ children }: IProps) => {
  const { state } = useContext(AuthContext);
  const { user, authPending } = state;
  if (authPending) {
    return <StyledSpinner size="60px" color={Theme.colors.darkBlue} margin={"auto"} />;
  }
  if (user) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};
