import { useState } from "react";

interface IuseToggle {
  isToggledOn: boolean;
  toggle: () => void;
}

export const useToggle = (initialValue = false): IuseToggle => {
  const [isToggledOn, setIsToggledOn] = useState(initialValue);
  const toggle = () => {
    setIsToggledOn((prevState) => {
      return !prevState;
    });
  };
  return { isToggledOn, toggle };
};
