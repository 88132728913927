export const ADD_TABLE_MODAL_TOGGLED = "ADD_TABLE_MODAL_TOGGLED";
export const GUEST_MODAL_TOGGLED = "GUEST_MODAL_TOGGLED";
export const UPLOAD_MODAL_TOGGLED = "UPLOAD_MODAL_TOGGLED";
export const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";
export const EDIT_TABLE_MODAL_TOGGLED = "EDIT_TABLE_MODAL_TOGGLED";
export const EDIT_GUEST_MODAL_TOGGLED = "EDIT_GUEST_MODAL_TOGGLED";
export const EDIT_EVENT_NAME_TOGGLED = "EDIT_EVENT_NAME_TOGGLED";
export const UPlOAD_IMAGE_MODAL_TOGGLED = "UPlOAD_IMAGE_MODAL_TOGGLED";
export const SHOW_PAYWALL = "SHOW_PAYWALL";

export type TTableModalToggled = {
  type?: typeof ADD_TABLE_MODAL_TOGGLED;
};
export type TGuestModalToggled = {
  type?: typeof GUEST_MODAL_TOGGLED;
};
export type TUploadModalToggled = {
  type?: typeof UPLOAD_MODAL_TOGGLED;
};
export type TCloseAllModals = {
  type?: typeof CLOSE_ALL_MODALS;
};
export type TEditTableModalToggled = {
  type?: typeof EDIT_TABLE_MODAL_TOGGLED;
};
export type TEditGuestModalToggled = {
  type?: typeof EDIT_GUEST_MODAL_TOGGLED;
};
export type TEventNameModalToggled = {
  type?: typeof EDIT_EVENT_NAME_TOGGLED;
};
export type TUploadImageModalToggled = {
  type?: typeof UPlOAD_IMAGE_MODAL_TOGGLED;
};

export type TShowPaywall = {
  type?: typeof SHOW_PAYWALL;
};

export type TAction =
  | TTableModalToggled
  | TGuestModalToggled
  | TUploadModalToggled
  | TCloseAllModals
  | TEditTableModalToggled
  | TEditGuestModalToggled
  | TShowPaywall
  | TEventNameModalToggled
  | TUploadImageModalToggled;

export interface IModalReducerState {
  isTableModalOpen: boolean;
  isGuestModalOpen: boolean;
  isUploadModalOpen: boolean;
  isEditTableModalOpen: boolean;
  isEditGuestModalOpen: boolean;
  isEventNameModalOpen: boolean;
  isImageModalOpen: boolean;
  showPayWall: boolean;
}

export const modalReducer = (state: IModalReducerState, action: TAction): IModalReducerState => {
  const {
    isTableModalOpen,
    isGuestModalOpen,
    isUploadModalOpen,
    isEditTableModalOpen,
    isEditGuestModalOpen,
    isEventNameModalOpen,
    isImageModalOpen,
  } = state;

  switch (action.type) {
    case ADD_TABLE_MODAL_TOGGLED: {
      return toggleBooleanProperty("isTableModalOpen", !isTableModalOpen);
    }
    case GUEST_MODAL_TOGGLED: {
      return toggleBooleanProperty("isGuestModalOpen", !isGuestModalOpen);
    }
    case UPLOAD_MODAL_TOGGLED: {
      return toggleBooleanProperty("isUploadModalOpen", !isUploadModalOpen);
    }
    case CLOSE_ALL_MODALS: {
      return {
        isTableModalOpen: false,
        isGuestModalOpen: false,
        isUploadModalOpen: false,
        isEditTableModalOpen: false,
        isEditGuestModalOpen: false,
        showPayWall: false,
        isEventNameModalOpen: false,
        isImageModalOpen: false,
      };
    }
    case EDIT_TABLE_MODAL_TOGGLED: {
      return toggleBooleanProperty("isEditTableModalOpen", !isEditTableModalOpen);
    }
    case EDIT_GUEST_MODAL_TOGGLED: {
      return toggleBooleanProperty("isEditGuestModalOpen", !isEditGuestModalOpen);
    }
    case EDIT_EVENT_NAME_TOGGLED: {
      return toggleBooleanProperty("isEventNameModalOpen", !isEventNameModalOpen);
    }
    case UPlOAD_IMAGE_MODAL_TOGGLED: {
      return toggleBooleanProperty("isImageModalOpen", !isImageModalOpen);
    }
    case SHOW_PAYWALL: {
      return toggleBooleanProperty("showPayWall", true);
    }
    default: {
      return state;
    }
  }
};

const toggleBooleanProperty = (property: string, newValue: boolean): IModalReducerState => {
  const values = {
    isTableModalOpen: false,
    isGuestModalOpen: false,
    isUploadModalOpen: false,
    isEditTableModalOpen: false,
    isEditGuestModalOpen: false,
    isEventNameModalOpen: false,
    isImageModalOpen: false,
    showPayWall: false,
  };

  return { ...values, [property]: newValue };
};
