import { AiOutlineQuestionCircle } from "react-icons/ai";
import { StyledQuestionMarkContainer, StyledTableInputHeading } from "../../Styles";

interface IProps {
  heading: string;
  toolTip?: string;
}

export const TableHeading = ({ heading, toolTip }: IProps) => {
  return (
    <StyledTableInputHeading>
      {heading}
      <StyledQuestionMarkContainer data-for="main" data-tip={toolTip} data-iscapture="true">
        <AiOutlineQuestionCircle />
      </StyledQuestionMarkContainer>
    </StyledTableInputHeading>
  );
};
