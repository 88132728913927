import { useLayoutEffect } from "react";

export const useFullHeight = (ref: any, offset = 0) => {
  useLayoutEffect(() => {
    function handleResize() {
      if (ref.current) {
        ref.current.style.height = window.innerHeight - offset + "px";
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [offset, ref]);
};
