import { useCallback, useContext } from "react";
import { ModalContext } from "../Context/ModalContext";
import { AuthContext } from "../Context/AuthContext";
import { EventContext } from "../Context/EventContext";

export const useEventNameModalToggled = () => {
  const { onEditEventNameModalOpened, onShowPaywall } = useContext(ModalContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const { state } = useContext(EventContext);
  return {
    onEventNameModalToggled: useCallback(() => {
      if (!user?.hasPaid) {
        onShowPaywall();
      } else {
        if (!state.event?.name) {
          onEditEventNameModalOpened();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.event?.name]),
  };
};
