import { useCallback, useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";
import { GuestFormat } from "../Context/tableReducer";

export const useUploadGuests = () => {
  const { uploadGuests } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    uploadGuests: useCallback((payload: any, format: GuestFormat | undefined) => {
      uploadGuests(eventId, payload, format);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  };
};
