import { ILogger } from ".";
import Bugsnag from "@bugsnag/js";

export class ProdLogger implements ILogger {
  log(message: string, ...params: any[]): void {}
  error(error: unknown, ...params: any[]): void {
    if (!(error instanceof Error)) {
      console.error("unknown error accoured");
      return;
    }
    Bugsnag.notify(error, function (event) {
      event.severity = "error";
      if (params.length > 0) {
        event.addMetadata("extra", { params });
      }
    });
  }
  warn(message: string, ...params: any[]): void {}
}
