import { useContext, useEffect } from "react";
import { EventContext } from "../Context/EventContext";
import { AuthContext } from "../Context/AuthContext";

export const useFetchOneEvent = () => {
  const { fetchOneEvent } = useContext(EventContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  useEffect(() => {
    fetchOneEvent(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
