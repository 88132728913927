import React, { useEffect, useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ListChildComponentProps } from "react-window";
import { SingleGuestDisplay } from ".";
import { TGuest } from "../Context/tableReducer";
import { isEqual } from "lodash";
import { StyledFixedSizeList } from "../Styles";
import { logger } from "../Logger";

interface IDroppableContainerProps {
  width: number;
  guests: TGuest[];
  droppableId: string;
  showDropZone?: boolean;
  tableRowToScrollTo?: number;
  getHeight: () => number;
}

const Row = React.memo(function Row(props: ListChildComponentProps) {
  const {
    data: { guests, tableRowToScrollTo, droppableId },
    index,
    style,
  } = props;
  const guest = guests[index];
  if (!guest) {
    return null;
  }
  return (
    <Draggable draggableId={guest.id} index={index} key={guest.id}>
      {(provided, snapshot) => (
        <SingleGuestDisplay
          provided={provided}
          guest={guest}
          style={style}
          droppableId={droppableId}
          isDragging={snapshot.isDragging}
          tableRowToScrollTo={index + 1 === tableRowToScrollTo ? tableRowToScrollTo : undefined}
        />
      )}
    </Draggable>
  );
}, isEqual);

export const DroppableContainer = React.memo(
  ({ droppableId, getHeight, width, guests, showDropZone = false, tableRowToScrollTo }: IDroppableContainerProps) => {
    logger.log(`droppableId >> ${droppableId}`);
    const listRef = useRef<any>();
    useEffect(() => {
      if (listRef && listRef.current && tableRowToScrollTo) {
        listRef.current.scrollToItem(tableRowToScrollTo - 1);
      }
    }, [tableRowToScrollTo]);
    return (
      <Droppable
        droppableId={droppableId}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          return (
            <SingleGuestDisplay provided={provided} isDragging={snapshot.isDragging} guest={guests[rubric.source.index]} droppableId={droppableId} />
          );
        }}
      >
        {(provided, snapshot) => {
          const itemCount = snapshot.isUsingPlaceholder ? guests.length + 1 : guests.length;

          return (
            <StyledFixedSizeList
              height={getHeight()}
              itemCount={showDropZone ? 0 : itemCount}
              itemSize={55}
              width={width}
              outerRef={provided.innerRef}
              itemData={{ guests: showDropZone ? [] : guests, tableRowToScrollTo, droppableId }}
              ref={listRef}
            >
              {Row}
            </StyledFixedSizeList>
          );
        }}
      </Droppable>
    );
  },
  isEqual
);
