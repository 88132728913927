import { StyledLayoutForInAppRoutesContainer, StyledTopPannelAndOutletContainer } from "../../Styles/LayoutForInAppRoutes.styled";
import { ProfilePannel, TopPannel } from "..";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useContext, useLayoutEffect, useEffect, useCallback } from "react";
import { TableContext } from "../../Context/TableContext";
import { useFetchTables } from "../../CustomHooks/useFetchTables";
import { ModalContext } from "../../Context/ModalContext";
import { useFetchOneEvent } from "../../CustomHooks/useFetchOneEvent";

export const LayoutForInAppRoutes = () => {
  useFetchTables();
  useFetchOneEvent();
  const location = useLocation();
  const height = location.pathname === "/preview" ? "100vh" : "100%";
  const {
    state: { guestsNeedSaving },
  } = useContext(TableContext);
  const {
    state: { isTableModalOpen },
  } = useContext(ModalContext);
  const showUnsaveChangesWarning = guestsNeedSaving || isTableModalOpen;
  const handleWindowClose = useCallback(
    (e: BeforeUnloadEvent) => {
      if (showUnsaveChangesWarning) {
        e.preventDefault();
        e.returnValue = "You have unsaved changes, are you sure you want to leave?";
      }
    },
    [showUnsaveChangesWarning]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", handleWindowClose);
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [handleWindowClose]);
  useLayoutEffect(() => {
    window.document.body.style.height = "unset";
    return () => {
      window.document.body.style.height = "100%";
    };
  }, []);
  if (location.pathname === "/") {
    return <Navigate to="/organise" />;
  }
  return (
    <StyledLayoutForInAppRoutesContainer>
      <ProfilePannel />
      <StyledTopPannelAndOutletContainer height={height}>
        <TopPannel />
        <Outlet />
      </StyledTopPannelAndOutletContainer>
    </StyledLayoutForInAppRoutesContainer>
  );
};
