import GlobalStyle from "./GlobalStyles";
import { TableProvider } from "./Context/TableContext";
import { ModalProvider } from "./Context/ModalContext";
import { AuthProvider } from "./Context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { AppInterface, Modal } from "./Components";
import { EventProvider } from "./Context/EventContext";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <AuthProvider>
        <EventProvider>
          <TableProvider>
            <ModalProvider>
              <AppInterface />
              <Modal />
            </ModalProvider>
          </TableProvider>
        </EventProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
