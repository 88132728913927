import { TableInput, TGuest, TGuestPath, TTable, TTableMap } from "../Context/tableReducer";
import { collection, doc, getDocs, writeBatch, setDoc, updateDoc, deleteDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "./config";
import { IDeleteGuest, IUpdateGuestDataInDB, IUpdateTableData } from "../Context/TableContext";
import { IEvent } from "../Context/EventReducer";
import { logger } from "../Logger";
import { IUserUpdateableFields } from "../Context/AuthReducer";
export interface ICreateTableData extends TableInput {
  tableId: string;
}
export interface ICreateUserArgs {
  email: string | null;
  uid: string;
  firstName: string;
  lastName: string;
}

const tablesCollection = "tables";
const guestsCollection = "guests";
const eventsCollection = "events";
export const usersCollection = "users";

export const addGuestsToTable = async (eventId: string, tableId: string, guests: TGuest[]) => {
  const guestsCollectionRef = collection(firestore, `${eventsCollection}/${eventId}/${tablesCollection}/${tableId}/${guestsCollection}`);
  const batch = writeBatch(firestore);

  guests.forEach((guest) => {
    const guestId = guest.id;
    const newGuestRef = doc(guestsCollectionRef, guestId);
    batch.set(newGuestRef, guest);
  });

  await batch.commit();
};

export const addGuestsToSidePannel = async (eventId: string, guests: TGuest[]) => {
  const sidePanelId = "sidePannel";
  const tableRef = doc(firestore, `${eventsCollection}/${eventId}/${tablesCollection}/${sidePanelId}`);
  const tableSnapshot = await getDoc(tableRef);

  if (!tableSnapshot.exists()) {
    await setDoc(tableRef, {
      tableNumber: 0,
    });
  }
  await addGuestsToTable(eventId, sidePanelId, guests);
};

export const getAllTablesForEvent = async (eventId: string): Promise<TTableMap> => {
  const tablesRef = collection(firestore, `${eventsCollection}/${eventId}/${tablesCollection}`);
  const tablesSnapshot = await getDocs(tablesRef);

  const tablesPromises = tablesSnapshot.docs.map(async (tableDoc) => {
    const tableData = tableDoc.data() as Omit<TTable, "guests">;
    const guestsRef = collection(tableDoc.ref, guestsCollection);
    const guestsSnapshot = await getDocs(guestsRef);

    const guests = guestsSnapshot.docs.map((guestDoc) => {
      return {
        id: guestDoc.id,
        ...guestDoc.data(),
      };
    });

    return {
      ...tableData,
      tableId: tableDoc.id,
      guests,
    } as TTable;
  });
  const tablesArray = await Promise.all(tablesPromises);
  const tablesMap: TTableMap = {};
  tablesArray.forEach((table) => {
    tablesMap[table.tableId] = table;
  });
  return tablesMap;
};

export const createTables = async (tables: ICreateTableData[], eventId: string) => {
  const tableCollectionRef = collection(doc(firestore, "events", eventId), "tables");
  const batch = writeBatch(firestore);
  for (const table of tables) {
    const { tableId } = table;
    const tableRef = doc(tableCollectionRef, tableId);
    const tableData = { ...table, tableId };
    batch.set(tableRef, tableData);
  }

  await batch.commit();
  logger.log("Tables created successfully.");
};

export async function updateTable(updateTableData: IUpdateTableData, eventId: string) {
  const { tableId, ...tableData } = updateTableData;
  const tableRef = doc(firestore, `events/${eventId}/tables/${tableId}`);
  await updateDoc(tableRef, tableData as { [key: string]: any });
  logger.log("Table updated successfully.");
}

export async function deleteTable(eventId: string, tableId: string) {
  const tableRef = doc(firestore, `${eventsCollection}/${eventId}/${tablesCollection}/${tableId}`);
  await deleteDoc(tableRef);
  logger.log("Table deleted successfully.");
}

export async function deleteGuest(args: IDeleteGuest, eventId: string) {
  const { guestId, tableIdToDeleteFrom } = args;
  const guestRef = doc(firestore, `${eventsCollection}/${eventId}/${tablesCollection}/${tableIdToDeleteFrom}/${guestsCollection}/${guestId}`);
  await deleteDoc(guestRef);
  logger.log("Guest deleted successfully.");
}

export const addEventName = async (eventName: string, eventId: string) => {
  const eventDocRef = doc(firestore, "events", eventId);
  const docSnapshot = await getDoc(eventDocRef);
  if (docSnapshot.exists()) {
    updateDoc(eventDocRef, { name: eventName });
    return;
  }
  setDoc(eventDocRef, { name: eventName });
};

export const addEventImage = async (imageUrl: string, eventId: string) => {
  const eventDocRef = doc(firestore, "events", eventId);
  const docSnapshot = await getDoc(eventDocRef);
  if (docSnapshot.exists()) {
    updateDoc(eventDocRef, { imageUrl });
    return;
  }
  setDoc(eventDocRef, { imageUrl });
};

export const getEvent = async (eventId: string): Promise<IEvent | undefined> => {
  const eventDocRef = doc(firestore, "events", eventId);
  const docSnapshot = await getDoc(eventDocRef);

  if (docSnapshot.exists()) {
    const eventData = docSnapshot.data();
    return eventData as IEvent;
  }
  return undefined;
};

export const moveGuestsInBatch = async (eventId: string, guestPaths: TGuestPath) => {
  const batchSize = 500;
  const allGuestIds = Object.keys(guestPaths);
  const totalBatches = Math.ceil(allGuestIds.length / batchSize);

  for (let i = 0; i < totalBatches; i++) {
    const batch = writeBatch(firestore);
    const batchGuestIds = allGuestIds.slice(i * batchSize, (i + 1) * batchSize);

    const guestDataPromises = batchGuestIds.map((guestId) => {
      const tablePath = guestPaths[guestId];
      if (tablePath.length > 1) {
        const sourceTableId = tablePath[0];

        if (sourceTableId !== tablePath[tablePath.length - 1]) {
          const sourceGuestRef = doc(firestore, `events/${eventId}/tables/${sourceTableId}/guests/${guestId}`);
          return getDoc(sourceGuestRef);
        }
      }
      return Promise.resolve(null);
    });

    const guestSnapshots = await Promise.all(guestDataPromises);
    const guestDataMap = guestSnapshots.reduce((acc: any, snapshot, index) => {
      if (snapshot && snapshot.exists()) {
        acc[batchGuestIds[index]] = snapshot.data();
      }
      return acc;
    }, {});

    for (const guestId of batchGuestIds) {
      const tablePath = guestPaths[guestId];
      if (tablePath.length > 1) {
        const sourceTableId = tablePath[0];
        const destinationTableId = tablePath[tablePath.length - 1];

        if (sourceTableId !== destinationTableId) {
          const sourceGuestRef = doc(firestore, `events/${eventId}/tables/${sourceTableId}/guests/${guestId}`);
          const destinationGuestRef = doc(firestore, `events/${eventId}/tables/${destinationTableId}/guests/${guestId}`);

          const guestData = guestDataMap[guestId];

          if (guestData) {
            batch.set(destinationGuestRef, guestData);
            batch.delete(sourceGuestRef);
          }
        }
      }
    }

    await batch.commit();
    logger.log(`Batch ${i + 1} of ${totalBatches} committed successfully.`);
  }
  logger.log("Guests moved successfully.");
};

export const updateGuest = async (args: IUpdateGuestDataInDB) => {
  const { eventId, tableIdToUpdateInDB, guestId, newGuestId, ...guestData } = args;
  const oldGuestRef = doc(firestore, `events/${eventId}/tables/${tableIdToUpdateInDB}/guests/${guestId}`);
  const newGuestRef = doc(firestore, `events/${eventId}/tables/${tableIdToUpdateInDB}/guests/${newGuestId}`);
  const batch = writeBatch(firestore);
  batch.delete(oldGuestRef);
  batch.set(newGuestRef, { ...guestData, guestId: newGuestId });
  await batch.commit();
  logger.log("Guest updated successfully.");
};

export const addUser = async (args: ICreateUserArgs) => {
  const { uid } = args;
  const userRef = doc(firestore, usersCollection, uid);
  const accountCreationTime = serverTimestamp();
  await setDoc(userRef, {
    ...args,
    hasPaid: false,
    accountCreationTime,
  });
  logger.log("User added successfully");
};

export const getUser = async (userId: string) => {
  const userRef = doc(firestore, usersCollection, userId);
  const userSnapshot = await getDoc(userRef);
  if (!userSnapshot.exists()) {
    return null;
  }
  return userSnapshot.data();
};

export const updateUser = async (info: IUserUpdateableFields, userId: string) => {
  const userRef = doc(firestore, "users", userId);
  await updateDoc(userRef, { ...info });
};
