import { useContext, useEffect } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";

export const useFetchTables = () => {
  const { fetchTables } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  useEffect(() => {
    fetchTables({ eventId });
  }, [eventId, fetchTables]);
};
