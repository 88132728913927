import ReactDOM from "react-dom";
import {
  StyledModalOverlay,
  StyledModalContentAndHeaderContainer,
  StyledModalHeader,
  StyledModalTitle,
  StyledModalContentContainer,
} from "../Styles";
import { IoClose } from "react-icons/io5";
import { Theme } from "../Theme";
import { useContext } from "react";
import { ModalContext } from "../Context/ModalContext";
import { selectIsModalOpen, selectModalStyle, selectModalTitle } from "../Selectors";
import { TableInputForm, AddGuestsForm, EditTableForm } from ".";
import { EditGuestForm } from "./EditGuestForm";
import { usePreventEnterSubmit } from "../CustomHooks";
import { PayWall } from "./PayWall";
import { EditEventNameForm } from "./EditEventNameForm";
import { UploadImageModal } from "./EventDetails/UploadImageModal";

export const ModalComponent = () => {
  usePreventEnterSubmit();
  document.body.style.overflow = "hidden";
  const { state, onCloseAllModals } = useContext(ModalContext);
  const modalTitle = selectModalTitle(state);
  const modalStyle = selectModalStyle(state);
  const isOpen = selectIsModalOpen(state);
  if (!isOpen) {
    document.body.style.overflow = "unset";
    return null;
  }

  return (
    <StyledModalOverlay onClick={onCloseAllModals}>
      <StyledModalContentAndHeaderContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...modalStyle}
      >
        <StyledModalHeader>
          <StyledModalTitle>{modalTitle}</StyledModalTitle>
          <IoClose style={{ fontSize: 25, color: Theme.colors.blackGrey }} onClick={onCloseAllModals} />
        </StyledModalHeader>
        <StyledModalContentContainer>
          {state.isTableModalOpen && <TableInputForm closeModal={onCloseAllModals} />}
          {state.isUploadModalOpen && <AddGuestsForm />}
          {state.showPayWall && <PayWall />}
          {state.isEditTableModalOpen && <EditTableForm closeModal={onCloseAllModals} />}
          {state.isEditGuestModalOpen && <EditGuestForm closeModal={onCloseAllModals} />}
          {state.isEventNameModalOpen && <EditEventNameForm closeModal={onCloseAllModals} />}
          {state.isImageModalOpen && <UploadImageModal />}
        </StyledModalContentContainer>
      </StyledModalContentAndHeaderContainer>
    </StyledModalOverlay>
  );
};

export const Modal = () => {
  return ReactDOM.createPortal(<ModalComponent />, document.getElementById("modal") as HTMLElement);
};
