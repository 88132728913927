import { useContext } from "react";
import { FlexAlignCenterContainer, FlexJustifyEndContainer, SpaceBetweenContainer, StyledText } from "../../Styles";
import { Theme } from "../../Theme";
import { TableContext } from "../../Context/TableContext";
import { selectNumberOfAssignedGuests, selectNumberOfGuests, selectNumberOfTables } from "../../Selectors";
import { ProgressBar } from "./ProgressBar";
const numberColor = Theme.colors.mediumGrey;
const textContainerWidth = "350px";
const textMargin = "15px 0px 0px 0px";
const progressBarMargin = "20px 0px 0px 0px";

export const EventStats = () => {
  const { state } = useContext(TableContext);
  const numTables = selectNumberOfTables(state);
  const numGuests = selectNumberOfGuests(state);
  const numAssignedGuests = selectNumberOfAssignedGuests(state);
  const percentageAssigned = (numAssignedGuests / numGuests) * 100;
  const showProgressBar = percentageAssigned > 0;
  return (
    <>
      <SpaceBetweenContainer width={textContainerWidth}>
        <StyledText textStyle="regular">Total No. of guests</StyledText>
        <StyledText textStyle="regular" color={numberColor}>
          {numGuests}
        </StyledText>
      </SpaceBetweenContainer>
      <SpaceBetweenContainer width={textContainerWidth} margin={textMargin}>
        <StyledText textStyle="regular">No. of guests assigned to tables</StyledText>
        <StyledText textStyle="regular" color={numberColor}>
          {numAssignedGuests}
        </StyledText>
      </SpaceBetweenContainer>
      <SpaceBetweenContainer width={textContainerWidth} margin={textMargin}>
        <StyledText textStyle="regular">No. of tables</StyledText>
        <StyledText textStyle="regular" color={numberColor}>
          {numTables}
        </StyledText>
      </SpaceBetweenContainer>
      {showProgressBar && (
        <FlexAlignCenterContainer margin={progressBarMargin}>
          <ProgressBar percentage={percentageAssigned} />
          <FlexJustifyEndContainer>
            <StyledText textStyle="regular" color={numberColor}>
              {Math.ceil(percentageAssigned)}%
            </StyledText>
          </FlexJustifyEndContainer>
        </FlexAlignCenterContainer>
      )}
    </>
  );
};
