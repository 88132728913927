import { StyledCenteredContainer, StyledText } from "../Styles";
import { Theme } from "../Theme";
import { Button } from "./Ui";
import { useNavigate } from "react-router-dom";

interface IProps {
  success: Boolean;
}

export const PaymentStatus = ({ success }: IProps) => {
  const navigate = useNavigate();
  const text = success ? "Payment Successful" : "Payment Unsuccessful";
  return (
    <StyledCenteredContainer>
      <StyledCenteredContainer boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)" boderRadius="10px" width="45%" height="45%" flexDirection="column">
        <StyledText textStyle="heading" color={Theme.colors.darkBlue}>
          {text}
        </StyledText>
        <Button
          text={"Continue"}
          onPress={() => {
            navigate("/organise", { replace: true });
          }}
          buttonStyles={{
            padding: "10px",
            width: "120px",
            color: Theme.colors.white,
            borderRadius: "10px",
            marginTop: "25px",
            marginBottom: "30px",
            backgroundColor: Theme.colors.darkBlue,
          }}
        />
        {!success && (
          <StyledText textStyle="regular" color={Theme.colors.red}>
            If you are experiencing issues, please contact support
          </StyledText>
        )}
      </StyledCenteredContainer>
    </StyledCenteredContainer>
  );
};
