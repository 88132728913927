import { IUpdateGuestData } from "../Context/TableContext";
import { GuestFormat, TGuest, TTableMap, guestFormatInputKeys } from "../Context/tableReducer";
import { logger } from "../Logger";
import { ISeatInfo } from "../Selectors";

interface ICSvProcessOutput {
  rowsNotProcessed: IRowNotProcessed[];
  guests: TGuest[];
}

export const getFromLocalStorage = <T>(key: string, initialValue: T) => {
  if (typeof window === "undefined") {
    return initialValue;
  }
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};

export const writeToLocalStorage = <T>(key: string, value: T) => {
  try {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    logger.error(error);
  }
};

export const formatGuestFullname = (guest: TGuest) => {
  if (guest.format === GuestFormat.Format2) {
    return `${guest.firstName.trim()} ${guest.lastName.trim()}`;
  }
  if (guest.format === GuestFormat.Format1) {
    return `${guest.title.trim()} ${guest.initial.trim()} ${guest.lastName.trim()}`;
  }
  throw new Error("invalid guest format");
};

export const formatGuestnameForSearch = (guest: TGuest) => {
  if (guest.format === GuestFormat.Format2) {
    return `${sanitizeString(guest.firstName)} ${sanitizeString(guest.lastName)}`;
  }
  if (guest.format === GuestFormat.Format1) {
    return `${sanitizeString(guest.title)} ${sanitizeString(guest.initial)} ${sanitizeString(guest.lastName)}`;
  }
  throw new Error("invalid guest format");
};

export const formatGuestInitials = (guest: TGuest) => {
  if (guest.format === GuestFormat.Format2) {
    return guest.firstName[0] + guest.lastName[0];
  }
  if (guest.format === GuestFormat.Format1) {
    return guest.initial[0] + guest.lastName[0];
  }
  throw new Error("invalid guest format");
};

const canProcessRow = (inputFields: any, format: GuestFormat) => {
  const requiredFields = guestFormatInputKeys[format];
  for (const field of requiredFields) {
    if (Object.keys(inputFields).length !== requiredFields.length) {
      return false;
    }
    if (typeof inputFields[field] !== "string") {
      return false;
    }
    if (!inputFields[field]) {
      return false;
    }
  }
  return true;
};

const getGuestFromRow = (inputFields: any, format: GuestFormat): TGuest => {
  if (format === GuestFormat.Format1) {
    return {
      initial: inputFields["initial"].trim(),
      title: inputFields["title"].trim(),
      lastName: inputFields["surname"].trim(),
      format: GuestFormat.Format1,
      id: getGuestId({ format: GuestFormat.Format1, title: inputFields["title"], lastName: inputFields["surname"], initial: inputFields["initial"] }),
    };
  }
  if (format === GuestFormat.Format2) {
    return {
      firstName: inputFields["first name"].trim(),
      lastName: inputFields["last name"].trim(),
      format: GuestFormat.Format2,
      id: getGuestId({ format: GuestFormat.Format2, firstName: inputFields["first name"], lastName: inputFields["last name"] }),
    };
  }
  throw new Error("cannot parse csv");
};
type ReasonRowFailed = "duplicate" | "value must be a string";
export interface IRowNotProcessed {
  row: number;
  reason: ReasonRowFailed;
}
export const processCSVInput = (input: any, format: GuestFormat, existingGuests: TGuest[]): ICSvProcessOutput => {
  const guests: TGuest[] = [];
  const rowsNotProcessed: IRowNotProcessed[] = [];
  const startCountingRowsFrom = 1;
  for (let i = 0; i < input.length; i++) {
    const sanitizedInput = Object.keys(input[i]).reduce((acc: { [key: string]: string }, cur) => {
      const newKey = sanitizeString(cur);
      acc[newKey] = input[i][cur];
      return acc;
    }, {});

    if (!canProcessRow(sanitizedInput, format)) {
      rowsNotProcessed.push({ row: i + 1 + startCountingRowsFrom, reason: "value must be a string" });
    } else {
      const guest = getGuestFromRow(sanitizedInput, format);
      const guestHasBeenUploaded = existingGuests.find(({ id }) => id === guest.id);
      if (guestHasBeenUploaded) {
        rowsNotProcessed.push({ row: i + 1 + startCountingRowsFrom, reason: "duplicate" });
      }
      if (!guestHasBeenUploaded) {
        guests.push(guest);
      }
    }
  }
  const guestsWithoutDuplicates = Array.from(new Set(guests.map((guest: TGuest) => guest.id))).map((id) => guests.find((a) => a.id === id)!);
  return {
    rowsNotProcessed,
    guests: guestsWithoutDuplicates,
  };
};

export const getFormatInWords = (format: GuestFormat) => {
  return format
    ?.split(",")
    .map((text, index) => {
      const hasComma = index !== format.split(",").length - 1;
      return text + (hasComma ? "," : "");
    })
    .join(" ");
};

export const sanitizeString = (str: string) => {
  return str.toLowerCase().trim();
};

export const getGuestId = (guestData: IUpdateGuestData): string => {
  if (guestData.format === GuestFormat.Format1) {
    return sanitizeString(guestData.initial.trim() + guestData.title.trim() + guestData.lastName.trim());
  }
  if (guestData.format === GuestFormat.Format2) {
    return sanitizeString(guestData.firstName.trim() + guestData.lastName.trim());
  }
  throw new Error("Cannot generate guestId without a valid format");
};

export const groupTablesAlphabetically = (tables: TTableMap) => {
  return Object.keys(tables).reduce((acc: TTableMap, cur: string) => {
    acc = {
      ...acc,
      [cur]: {
        ...tables[cur],
        guests: tables[cur].guests.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        }),
      },
    };
    return acc;
  }, {});
};

export const alphabeticalNameSort = (a: TGuest, b: TGuest) => {
  if (a.lastName < b.lastName) {
    return -1;
  }
  if (a.lastName > b.lastName) {
    return 1;
  }
  return 0;
};

export const sortGuestsAlphabetically = (seatInfo: ISeatInfo[]) => {
  return [...seatInfo].sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  });
};
