import { IAlphabeticalList } from "../../Selectors";
import { StyledEventHeading, StyledOverlay, StyledSeatingPlanImageBackgound, StyledSeatingPlanInnerContainer } from "../../Styles";
import { Theme } from "../../Theme";
import { AlphabeticalLetter } from "./AlphabeticalLetter";
interface IProps {
  alphabeticalList: IAlphabeticalList[];
  autoFillSpace: string;
  eventInfo: {
    eventName: string;
    eventImageUrl?: string;
  };
  textStyle?: string;
}
const borderStyle = {
  border: "none",
  borderRadius: "15px",
};

export const AlphabeticalList = ({ alphabeticalList, autoFillSpace, textStyle, eventInfo: { eventName, eventImageUrl } }: IProps) => {
  return (
    <StyledSeatingPlanImageBackgound imageUrl={eventImageUrl}>
      <StyledOverlay backgroundColor="rgba(255,255,255,0.7)" overflow="scroll">
        <StyledSeatingPlanInnerContainer autoFillSpace={autoFillSpace}>
          <StyledEventHeading>{eventName}</StyledEventHeading>
          {alphabeticalList.map((letterAndList) => {
            return (
              <AlphabeticalLetter
                letterAndList={letterAndList}
                key={letterAndList.letter}
                textStyle={textStyle}
                fontColor={Theme.colors.black}
                fontFamily={"Prata"}
                fontSize="38px"
                borderStyle={borderStyle}
                backgroundColor={"transparent"}
                hasLinkId={true}
                nameWidth="190px"
              />
            );
          })}
        </StyledSeatingPlanInnerContainer>
      </StyledOverlay>
    </StyledSeatingPlanImageBackgound>
  );
};
