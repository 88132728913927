import { useContext, useState } from "react";
import { TextInputField, Button } from ".";
import { Theme } from "../Theme";
import { Formik, Field, FieldProps, Form } from "formik";
import * as Yup from "yup";
import { FixedSizeContainer, Flex1Container, StyledText } from "../Styles";
import { StyledConfirmationButtonContainer } from "../Styles/EventDetails.styled";
import { useAddEventName } from "../CustomHooks/useAddEventName";
import { EventContext } from "../Context/EventContext";
import { selectEventName } from "../Selectors/eventSelectors";

interface IEditTableFormProps {
  closeModal: () => void;
}
const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white, fontSize: "16px" },
  inputFieldAndErrorDisplayContainerStyle: { width: "100%", marginTop: "15px" },
};
const buttonStyles = {
  width: "100%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
  marginTop: "20px",
};
const eventNameSchema = Yup.object().shape({
  eventName: Yup.string(),
});
const confirmSaveMessage = "You will not be able to change the name after saving. Are you sure you want to continue?";
export const EditEventNameForm = ({ closeModal }: IEditTableFormProps) => {
  const [showSaveConfirmation, setshowSaveConfirmation] = useState(false);
  const { state } = useContext(EventContext);
  const { addEventName } = useAddEventName();
  const showSaveEventNameForm = !showSaveConfirmation;

  return (
    <Formik
      initialValues={{
        eventName: selectEventName(state),
      }}
      onSubmit={async (values) => {
        await addEventName(values.eventName);
        closeModal();
      }}
      validationSchema={eventNameSchema}
    >
      {({ submitForm }) => {
        return (
          <FixedSizeContainer width="250px" height="180px">
            {showSaveEventNameForm && (
              <>
                <Flex1Container>
                  <Form>
                    <Field name="eventName">
                      {({ field, meta }: FieldProps) => {
                        return (
                          <TextInputField label="Event Name" overrideStyles={textInputStyle} {...field} {...meta} placeholder="Enter event name" />
                        );
                      }}
                    </Field>
                  </Form>
                </Flex1Container>
                <Button
                  text="Save"
                  buttonStyles={buttonStyles}
                  onPress={() => {
                    setshowSaveConfirmation(true);
                  }}
                />
              </>
            )}
            {showSaveConfirmation && (
              <>
                <Flex1Container>
                  <StyledText textStyle="regular" textAlign="justify" lineHeight="23px">
                    {confirmSaveMessage}
                  </StyledText>
                </Flex1Container>
                <StyledConfirmationButtonContainer>
                  <Button
                    text="Back"
                    buttonStyles={{
                      ...buttonStyles,
                      backgroundColor: Theme.colors.white,
                      color: Theme.colors.darkBlue,
                      border: `1px solid ${Theme.colors.darkBlue}`,
                      marginRight: "15px",
                    }}
                    onPress={() => {
                      setshowSaveConfirmation(false);
                    }}
                  />
                  <Button text="Yes" buttonStyles={buttonStyles} isLoading={state.isAddingEvent} onPress={submitForm} />
                </StyledConfirmationButtonContainer>
              </>
            )}
          </FixedSizeContainer>
        );
      }}
    </Formik>
  );
};
