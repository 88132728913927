import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { TableContext } from "../Context/TableContext";

export const useLogout = () => {
  const { logout } = useContext(AuthContext);
  const { resetState } = useContext(TableContext);
  return async () => {
    resetState();
    await logout();
  };
};
